<form
    autocomplete="off"
    novalidate
    [formGroup]="searchForm">
  <div class="input-group">
    <div class="input-group-prepend" *ngIf="!isCompact" aria-hidden="true">
      <span class="input-group-text" id="search-icon">
        <i class="fas fa-search" *ngIf="!isLoading"></i>
        <i class="fas fa-circle-notch fa-spin" *ngIf="isLoading"></i>
      </span>
    </div>

    <span class="input-container">
      <input
          #searchInput
          id="search-input"
          type="text"
          role="textbox"
          class="form-control outline"
          [tabindex]="isCompact ? -1 : 0"
          [placeholder]="isAdmin ? 'Search by name' : 'Search people and departments'"
          formControlName="nameSearch"
          [ngClass]="{ 'compact' : isCompact, 'border-0' : !isAdmin }"
          placement="bottom-right bottom-left"
          [ngbTypeahead]="search"
          [resultTemplate]="searchListing"
          [inputFormatter]="searchFormatter"
          (selectItem)="selectedItem($event)"
          appNgbDropdownScroll/>
      <span [ngClass]="{ 'compact' : isCompact }">
        <button class="btn btn-primary" type="button" (click)="clearResultsDropdown(true); searchInput.focus()" *ngIf="!isCompact && !!searchForm.get('nameSearch').value" aria-label="clear search">
          <span class="btn-content" tabindex="-1">
            <i class="fas fa-times-circle" aria-hidden="true"></i>
          </span>
        </button>
        <span class="btn btn-primary bg-nau-yellow append-icon" *ngIf="isCompact" aria-hidden="true">
          <i class="fas fa-search"></i>
        </span>
      </span>
    </span>
  </div>
</form>

<ng-template #searchListing let-r="result" let-t="term">
  <ng-container *ngIf="!!r.isManualEntry">
    <ng-container *ngIf="r.resultCount === 0">
      No results
    </ng-container>
    <ng-container *ngIf="personHitsCount + this.departmentHitsCount > 0">
      {{ r.value }}
      <span class="search-result-length" *ngIf="!isLoading && r.resultCount">{{ r.resultCount }}</span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!r.isManualEntry && !!r.uid && !r.isSeparator">
    <ngb-highlight [result]="r.givenName +  ' ' + r.sn" [term]="t"></ngb-highlight>
    <div class="title alt-text">
      <ng-container *ngIf="!isAdmin">
        <div class="text-wrap">
          <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
          <span *ngIf="r.title && r.jobs?.length">, </span>
          <ngb-highlight [result]="r.jobs[0].deptDescr" [term]="t" *ngIf="r.jobs?.length"></ngb-highlight>
        </div>
      </ng-container>
      <ng-container *ngIf="isAdmin && r.descr.length">
        <span class="text-wrap">{{ r.descr[0] }}</span>
      </ng-container>
    </div>
  </ng-container>
  <ngb-highlight [result]="r.n__dept_longdescr" [term]="t" *ngIf="!r.isManualEntry && !r.uid && !r.isSeparator"></ngb-highlight>
  <hr *ngIf="r.isSeparator" appNgbTypeaheadSeparator />
</ng-template>
