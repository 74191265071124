import { Directive, ElementRef, HostListener, Input, ContentChildren, QueryList, AfterViewInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appKeyboardFocusContainer]'
})
export class KeyboardFocusContainerDirective implements AfterViewInit {

  @Input() isFirstFocusElement = false;
  @ContentChildren('keyFocus', { descendants: true }) focusableChildren: QueryList<ElementRef>;
  private currentIndex: number;
  private childUnsubscribers: { blur: Function, click: Function, focus: Function }[];
  private focusableElements: ElementRef[];
  private hasFocus: boolean;
  private isKeyDown = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.focusableChildren.changes.subscribe(
      change => {
        this.initChildren();
      });
    this.initChildren();
  }

  @HostListener('keydown', ['$event'])
  keyboardDown(event) {
     if (this.hasFocus) {
      if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
        if (!this.isKeyDown) {
          this.isKeyDown = true;
          this.arrowNavigation(event.code);
          event.preventDefault();
        }
        return false;
      }
      return true;
    } else if (this.isFirstFocusElement && event.code === 'Tab') {
      if (window.document.activeElement.tagName === 'BODY') {
        this.focusableChildren.first.nativeElement.focus();
        event.preventDefault();
      }
      this.isFirstFocusElement = false;
    }
  }

  @HostListener('keyup', ['$event'])
  keyboardUp(event) {
    if (this.hasFocus) {
      this.isKeyDown = false;
    }
  }

  private arrowNavigation(eventCode: string) {
    if (eventCode === 'ArrowDown') {
      let nextIndex = (this.currentIndex === this.focusableElements.length - 1) ? 0 : this.currentIndex + 1;
      while (this.focusableElements[nextIndex].nativeElement.disabled && nextIndex !== this.currentIndex) {
        nextIndex++;
        if (nextIndex >= this.focusableElements.length) {
          nextIndex = 0;
        }
      }
      this.focusableElements[nextIndex].nativeElement.focus();
    }
    if (eventCode === 'ArrowUp') {
      let nextIndex = (this.currentIndex === 0) ? this.focusableElements.length - 1 : this.currentIndex - 1;
      while (this.focusableElements[nextIndex].nativeElement.disabled && nextIndex !== this.currentIndex) {
        nextIndex--;
        if (nextIndex < 0) {
          nextIndex = this.focusableElements.length - 1;
        }
      }
      this.focusableElements[nextIndex].nativeElement.focus();
    }
  }

  private elementBlur(event) {
    this.hasFocus = false;
  }

  private elementFocus(event, index?) {
    if (this.currentIndex !== undefined) {
      __ngRendererSetElementAttributeHelper(this.renderer, this.focusableElements[this.currentIndex].nativeElement, 'tabindex', '-1');
    }
    if (this.focusableElements[index] !== undefined) {
      __ngRendererSetElementAttributeHelper(this.renderer, this.focusableElements[index].nativeElement, 'tabindex', '0');
    }
    this.hasFocus = true;
    this.currentIndex = index;
  }

  private initChildren() {
    this.focusableElements = this.focusableChildren.toArray();
    if (!!this.childUnsubscribers) {
      this.childUnsubscribers.forEach(
        subscription => {
          subscription.blur();
          subscription.focus();
          subscription.click();
        });
    }
    this.childUnsubscribers = [];
    this.focusableElements.forEach(
      (element, index) => {
        // remove tab index from all elements
        __ngRendererSetElementAttributeHelper(
        this.renderer, element.nativeElement, 'tabindex', '-1');
          this.childUnsubscribers[index] = {
            blur : this.renderer.listen(element.nativeElement, 'blur', (event) => this.elementBlur(event)),
            click : this.renderer.listen(element.nativeElement, 'focus', (event) => this.elementFocus(event, index)),
            focus : this.renderer.listen(element.nativeElement, 'click', (event) => this.elementFocus(event, index))
          };
        });
    if (this.focusableElements.length) {
      let index = 0;
      // get first non-disabled control
      while (this.focusableElements[index].nativeElement.disabled && index < this.focusableElements.length) {
        index++;
      }
      if (index < this.focusableElements.length) {
        // add tab index to first non-disabled control
        __ngRendererSetElementAttributeHelper(this.renderer, this.focusableElements[index].nativeElement, 'tabindex', '0');
        this.currentIndex = index;
      }
    }
  }

}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ':') {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ['', name];
}

function __ngRendererSetElementAttributeHelper(
      renderer: AnyDuringRendererMigration,
      element: AnyDuringRendererMigration,
      namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    } else {
        renderer.removeAttribute(element, name, namespace);
    }
}
