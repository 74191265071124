import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { Location } from '@angular/common';
import {HttpParams} from '@angular/common/http';
import { ElasticService } from './elastic.service';
import { combineLatest, Subscription, Observable, of, forkJoin } from 'rxjs';
import { filter, map, tap, catchError } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SearchStateService {

  private all: Results = {};
  private faculty: Results = {};
  private staff: Results = {};
  private departments: Results = {};
  private advising: Results = {};
  private currentUrl: string;

  @Output() paramsChange: EventEmitter<any> = new EventEmitter();
  stateEvents: StateEvents = {};

  constructor(
      private _router: Router,
      private _activatedRoute: ActivatedRoute,
      private _location: Location,
      private _elastic: ElasticService) {
    this.stateEvents.all = new EventEmitter();
    this.stateEvents.faculty = new EventEmitter();
    this.stateEvents.staff = new EventEmitter();
    this.stateEvents.departments = new EventEmitter();
    this.stateEvents.advising = new EventEmitter();
    this.init();
  }

  init() {
    this.all.sorch = {};
    this.departments.sorch = {};
    this.departments.searchFields = {};
    this.advising.sorch = {};
    this.advising.searchFields = {};
    this.faculty.searchFields = {};
    this.faculty.sorch = {};
    this.staff.searchFields = {};
    this.staff.sorch = {};

    combineLatest([this._router.events, this._activatedRoute.queryParams])
      .pipe(filter((value, index) => value[0] instanceof NavigationEnd))
      .subscribe(
        results => {
          const navEvent = results[0] as NavigationEnd ;
          this.currentUrl = navEvent.url;
          const queryPersonParam = results[1]['person'];
          const params = this.stripQueryPersonParam(results[1]);
          switch (navEvent.url.split('?')[0]) {
          case '/':
            this.paramsChange.emit(results[1]);
            this.setDefaultState(navEvent.url, params.search, queryPersonParam);
            break;
          case '/faculty':
            this.setAdvancedState('faculty', params, queryPersonParam);
            break;
          case '/staff':
            this.setAdvancedState('staff', params, queryPersonParam);
            break;
          case '/departments':
            this.setDepartmentsState('departments', navEvent.url, params, queryPersonParam);
            break;
          case '/advising':
            this.setadvisingState('advising', navEvent.url, params, queryPersonParam);
            break;
          }
        });
  }

  public get $all(): Results {
    return this.all;
  }

  public set $all(value: Results) {
    this.all = value;
  }

  public get $faculty(): Results {
    return this.faculty;
  }

  public set $faculty(value: Results) {
    this.faculty = value;
  }

  public get $staff(): Results {
    return this.staff;
  }

  public set $staff(value: Results) {
    this.staff = value;
  }

  public get $departments(): Results {
    return this.departments;
  }

  public set $departments(value: Results) {
    this.departments = value;
  }

  public get $advising(): Results {
    return this.advising;
  }

  public set $advising(value: Results) {
    this.advising = value;
  }

  stripQueryPersonParam(queryParams: any): any {
    const querySearchParams = {};
    Object.keys(queryParams).forEach(key => {
      const temp = queryParams[key];
      if (key !== 'person' && temp) {
        querySearchParams[key] = temp;
      }
    });
    return querySearchParams;
  }

  setQueryParams(value?: any) {
    const params = {};
    if (value) {
      Object.keys(value).forEach(
        key => {
          if (value[key]) {
            params[key] = value[key];
          }
        });
    }
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams: params,
        replaceUrl: true
      });
  }

  setNavigation(value?: any, excludePerson?: boolean) {
    const params = {};
    if (value) {
      Object.keys(value).forEach(
        key => {
          if (value[key] && !(excludePerson && key === 'person')) {
            params[key] = value[key];
          }
        });
    }
    this._router.navigate(
      [],
      {
        queryParams: params,
      });
  }

  getSearchableTerm(searchVal: string, minLength = 2): string {
    if (!searchVal) { return ''; }
    const querySearchParam = searchVal.trim().replace(/[^a-zA-Z.*,'\-\" ]/g, '');
    return querySearchParam.length > minLength ? querySearchParam : '';
  }

  private setDefaultState(url: string, searchVal: string, queryPersonParam: string) {
    if (!this.all.searchFields) {
      this.all.searchFields = {};
    }
    const parsedVal = this.getSearchableTerm(searchVal);
    const isUrlstateConflict = JSON.stringify({ name: parsedVal }) !== JSON.stringify(this.all.searchFields);
    if (isUrlstateConflict) {
      this.all.searchFields.name = parsedVal;
      if (parsedVal && parsedVal.length > 2) {
        this._elastic.searchAll(parsedVal).subscribe(
          results => {
            if (url !== this.currentUrl) {
              // stale state
            } else {
              this.all.people = results.people;
              this.all.departments = results.departments;
              this.all.selectedValue = this.all.people.find(person => person.uid === queryPersonParam);
              this.stateEvents['all'].emit(this.all);
            }
          });
      } else {
        this.all.people = [];
        this.all.departments = [];
        if (queryPersonParam) {
          this._elastic.searchContact(queryPersonParam).subscribe(
            contactResponse => {
              if (!(this.all.selectedValue = contactResponse)) {
                // person not found
              }
              this.stateEvents['all'].emit(this.all);
            });
        } else {
          this.all.selectedValue = null;
          this.stateEvents['all'].emit(this.all);
        }
      }
    } else {
      if (!queryPersonParam) {
        this.all.selectedValue = null;
      } else {
        if (this.all.people && this.all.people.length) {
          let foundPerson;
          if (foundPerson = this.all.people.find(person => person.uid === queryPersonParam)) {
            this.all.selectedValue = foundPerson;
          }
        } else {
          if (url !== this.currentUrl) {
          } else {
            this._elastic.searchContact(queryPersonParam).subscribe(
              contactResponse => {
                if (!(this.all.selectedValue = contactResponse)) {
                  // person not found
                }
                this.stateEvents['all'].emit(this.all);
              });
          }
        }
      }
      this.stateEvents['all'].emit(this.all);
    }
  }

  private setadvisingState(stateName: string, url: string, params: any, queryPersonParam: string) {
   this.stateEvents[stateName].emit({ org: params.org, person: queryPersonParam, gateway: params.gateway, source: params.src });
  }

  private setDepartmentsState(stateName: string, url: string, params: any, queryPersonParam: string) {
    const state = this[stateName];
    state.source = params.src;
    const stateEvents = this.stateEvents[stateName];
    const urlParams = {
      deptid: params.id || '',
      personId: queryPersonParam || ''
    };
    const stateParams = {
      deptid: !!state.selectedDepartment ? state.selectedDepartment.deptid : '',
      personId: !!state.selectedValue ? state.selectedValue.uid : ''
    };
    const isDeptIdConflict = urlParams.deptid !== stateParams.deptid;
    const isPersonIdConflict = urlParams.personId !== stateParams.personId;
    if (isDeptIdConflict) {
      if (params['id']) {
        if (url !== this.currentUrl) {
          console.log('stale state');
        } else {
          state.isLoading = true;
          this._elastic.getDepartment(params['id']).subscribe(
            department => {
              if (!department) { return; }
              // clean up "empty" string values, which often contain a single space
              Object.keys(department).forEach(key => {
                if (typeof department[key] === 'string') {
                  department[key] = department[key].trim();
                }
              });
              this.loadDepartmentPeople(department.deptid).subscribe(
                people => {
                  let selectedPerson;
                  if (isPersonIdConflict) {
                    if (!urlParams.personId) {
                      state.selectedValue = null;
                    } else {
                      if (selectedPerson = people.find(person => person.uid === queryPersonParam)) {
                        state.selectedValue = selectedPerson;
                      }
                    }
                  }
                  state.isLoading = false;
                  state.selectedDepartment = department;
                  stateEvents.emit({department: department, people: people, person: selectedPerson});
                });
            }, error => { state.isLoading = false; });
        }
      } else {
        state.selectedDepartment = null;
        state.selectedValue = null;
        stateEvents.emit({ department: null, people: null, person: null});
      }
    } else if (isPersonIdConflict) {
      state.selectedValue = state.people.members.listed.find(person => person.uid === queryPersonParam);
      stateEvents.emit({
        department: null,
        people: null,
        person: state.selectedValue});
    } else {
      stateEvents.emit({
        department: null,
        people: null,
        person: null});
    }
  }


  private loadDepartmentPeople(departmentId: string) {
    return this._elastic.getDepartmentPeople(departmentId)
      .pipe(
        map(data =>  this._elastic.mapElasticData(data.hits),
        error => {
          return of([]);
        }));
  }

  private setAdvancedState(stateName: string, params: any, queryPersonParam: string) {
    const state = this[stateName];
    const hasUrlParams = !!Object.keys(params).length;
    if (hasUrlParams) {
      const isUrlstateConflict = JSON.stringify(params) !== JSON.stringify(state.searchFields);
      if (isUrlstateConflict) {
        if (!!queryPersonParam) {
          const isPreviousSearchResults = !!state.people;
          if (isPreviousSearchResults) {
            this.setSelectedPersonFromResults(stateName, queryPersonParam);
            this.stateEvents[stateName].emit({ people: state.people, person: state.selectedValue });
          } else {
            this.getPeople(stateName, params).subscribe(
              people => {
                const selectedPerson = people.find(person => person.uid === queryPersonParam);
                if (!!selectedPerson) {
                  state.selectedValue = selectedPerson;
                }
                this.stateEvents[stateName].emit({ people: people, person: state.selectedValue });
              });
          }
        } else {
          state.selectedValue = null;
          this.getPeople(stateName, params).subscribe(
            people => {
              const isSingleSearchResult = people.length === 1;
              if (isSingleSearchResult) {
                state.selectedValue = people[0];
              } else {
                state.selectedValue = null;
              }
              this.stateEvents[stateName].emit({ people: state.people, person: state.selectedValue });
            });
        }
      } else {
        if (!!queryPersonParam) {
          const isPreviousSearchResults = !!state.people;
          if (isPreviousSearchResults) {
            this.setSelectedPersonFromResults(stateName, queryPersonParam);
            this.stateEvents[stateName].emit({ people: state.people, person: state.selectedValue });
          } else {
            this._elastic.searchContact(queryPersonParam).subscribe(contactResponse => {
              const isContactResponse = !!(state.selectedValue = contactResponse);
              if (isContactResponse) {
                this.stateEvents[stateName].emit({ people: null, person: state.selectedValue });
              }
            });
          }
        } else {
          const isSingleSearchResult = state.people && state.people.length === 1;
          if (isSingleSearchResult) {
            state.selectedValue = state.people[0];
          } else {
            state.selectedValue = null;
          }
          this.stateEvents[stateName].emit({ people: state.people, person: state.selectedValue });
        }
      }
    } else  {
      this.stateEvents[stateName].emit(null);
    }
  }

  private getPeople(stateName: string, searchParams: any): Observable<any> {
    this[stateName].searchFields = searchParams;
    let searchEvent;
    if (stateName === 'faculty') {
      searchEvent = this._elastic.searchFaculty;
    } else if (stateName === 'staff') {
      searchEvent = this._elastic.searchStaff;
    }
    return searchEvent.call(this._elastic, this[stateName].searchFields, 5000).pipe(
      map((result: any) => {
        this[stateName].people = this._elastic.mapElasticData(result.hits);
        return this[stateName].people;
      })
    );
  }

  private setSelectedPersonFromResults(stateName: string, queryPersonParam: string) {
    let selectedPerson;
    if (this[stateName].people && (selectedPerson = this[stateName].people.find(person => person.uid === queryPersonParam))) {
      this[stateName].selectedValue = selectedPerson;
    }
  }
}

interface Results {
  people?: any;
  departments?: Array<any>;
  selectedValue?: any;
  selectedDepartment?: any;
  searchFields?: SearchFields;
  sorch?: Sorch;
  isLoading?: boolean;
  urlState?: string;
  state?: string;
  source?: string;
}

interface SearchFields {
  department?: string;
  deptid?: string;
  keyword?: string;
  name?: string;
  title?: string;
  browseByLetter?: string;
}

interface Sorch {
  sortOn?: string;
  sortDirection?: string;
  filter?: string;
  typeFilter?: string;
  departmentFilter?: string;
  departmentAlphaArr?: string[];
  departmentTypeFilter?: string;
  subdepartments?: boolean;
}

interface StateEvents {
  all?: EventEmitter<any>;
  faculty?: EventEmitter<any>;
  staff?: EventEmitter<any>;
  departments?: EventEmitter<any>;
  advising?: EventEmitter<any>;
}

