import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from './shared/shared.module';
import { AllComponent } from './components/all/all.component';
import { FacultyComponent } from './components/faculty/faculty.component';
import { StaffComponent } from './components/staff/staff.component';
import { DepartmentsComponent } from './components/departments/departments.component';
import { DirectoryComponent } from './directory.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DynamicLayoutService } from './shared/services/dynamic-layout.service';
import { SpaSettingsService } from './shared/services/spa-settings.service';
import { AdvisingComponent } from './components/advising/advising.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: AllComponent,
    children: [
      {
        path: 'search',
        component: AllComponent,
        children: [
          {
            path: 'person',
            component: AllComponent,
          },
        ]
      },
    ]
  },
  {
    path: 'faculty',
    component: FacultyComponent
  },
  {
    path: 'person/:id',
    component: ProfileComponent,
    canActivate: [SpaSettingsService]
  },
  {
    path: 'staff',
    component: StaffComponent
  },
  {
    path: 'departments',
    component: DepartmentsComponent,
    canActivate: [DynamicLayoutService]
  },
  {
    path: 'advising',
    component: AdvisingComponent,
    canActivate: [DynamicLayoutService]
  },
];

@NgModule({
  declarations: [
    AllComponent,
    FacultyComponent,
    StaffComponent,
    DepartmentsComponent,
    DirectoryComponent,
    ProfileComponent,
    AdvisingComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    SharedModule.forRoot()
  ]
})
export class DirectoryModule {}
