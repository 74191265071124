import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SearchStateService } from '../../services/search-state.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})
export class NavComponent implements OnInit {

  @Input()
  activeTab: string;
  @Input()
  refreshOverride: boolean;
  @Output()
  refresh: EventEmitter<void> = new EventEmitter<void>();
  tabData: any;
  searchState: any;

  constructor(private _router: Router, private _searchState: SearchStateService) { }

  ngOnInit() {
    this.tabData = [
      { name: 'all', url: '/' },
      { name: 'faculty', url: '/faculty' },
      { name: 'staff', url: '/staff' },
      { name: 'departments', url: '/departments' }
    ];
    this.searchState = this._searchState.$all;
  }

  getQueryParams(tabName: string, isAdvancedSearch: boolean) {

    let params;
    if (tabName !== 'departments') {
      params = Object.assign({}, this._searchState[tabName].searchFields);
    } else  {
      params = {};
    }
    if (this._searchState[tabName].selectedValue && !(isAdvancedSearch && this._searchState[tabName].people.length === 1)) {
      params['person'] = this._searchState[tabName].selectedValue.uid;
    }
    if (isAdvancedSearch && !this._searchState[tabName].people) {
      params = {};
    }
    if (tabName === 'all') {
      if (params.name) {
        params.search = params.name;
      }
      delete params.name;
    }
    return params;
  }

  navigate(event, tab) {
    const isAdvancedSearch = tab.name === 'staff' || tab.name === 'faculty';
     if (!this.refreshOverride && tab.name === this.activeTab) {
      if (isAdvancedSearch) {
        this._searchState[tab.name].searchFields = {};
      }
      this.refresh.emit();
    } else {
      const queryParams = this.getQueryParams(tab.name, isAdvancedSearch);
      if (tab.name === 'departments' && !!this._searchState[tab.name].selectedDepartment) {
        queryParams.id = this._searchState[tab.name].selectedDepartment.deptid;
      }
      this._router.navigate(
          [tab.url],
          { queryParams: queryParams });
    }
  }

  handleSearchSelectAll(value: any) {
    if (value.results.personHitsCount <= 10 && value.results.personHitsCount <= 10) {
      this.searchState.people = value.results.people;
      this.searchState.departments = value.results.departments;
      this.searchState.searchFields = { name: value.searchTerm };
    }
    this._router.navigate([`./`], { queryParams: { search: value.searchTerm } });
  }

  handleSearchSelectPerson(value: any, clearPreviousResults?: boolean) {
    this.searchState.selectedValue = value.selectedValue;
    this.searchState.people = [];
    this.searchState.departments = [];
    this.searchState.searchFields = { name: '' };
    this._router.navigate([`./`], { queryParams: { person: value.selectedValue.uid } });
  }

  handleSearchSelectDepartment(value: any, clearPreviousResults?: boolean) {
    this._router.navigate([`./departments`], { queryParams: { id: value.selectedValue.deptid } });
  }


}
