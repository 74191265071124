import { Injectable } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';


@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor(private _scrollToService: ScrollToService) { }

  scrollTo(id?: string) {

    const config: ScrollToConfigOptions = {
      target: id || 'scrollTop',
      duration: 0
    };
    if (!!id) {
      setTimeout( () => {
        this._scrollToService.scrollTo(config);
      });
    } else {
      this._scrollToService.scrollTo(config);
    }
  }
}
