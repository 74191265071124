import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElasticService } from '../../shared/services/elastic.service';
import { SearchStateService } from '../../shared/services/search-state.service';
import { ContactService } from '../../shared/services/contact.service';
import { ScrollService } from '../../shared/services/scroll.service';
import { SpaSettingsService } from './../../shared/services/spa-settings.service';
import { HeaderService } from '../../shared/services/header.service';


@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.sass']
})
export class DepartmentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('alphaIndex') alphaIndex: ElementRef;
  @ViewChild('deptFilter') deptFilter: ElementRef;

  deptMembers: Array<any>;
  deptAdmins: Array<any>;

  stateChange: Subscription;
  departmentsSet: Array<any>;
  showDepartments = false;
  newSearchInput = new UntypedFormControl('');
  filterInput = new UntypedFormControl('');
  departmentFilterInput = new UntypedFormControl('');
  departmentTypeFilterInput = new UntypedFormControl('all');
  allDepartmentsFiltered = false;
  allPeopleFiltered = false;
  isLoadingDepartments: boolean;
  newSearchTerm: string;
  listComplete = new Subject();
  subDepartments: [];
  state: any;
  navMobileSize = 768;
  mobileNavHeight = 90;
  jobFilter;


  constructor(
    private _elastic: ElasticService,
    public _searchState: SearchStateService,
    private _contactservice: ContactService,
    private _scrollService: ScrollService,
    private _settingsService: SpaSettingsService,
    private _headerService: HeaderService,
    private cdr: ChangeDetectorRef) {
      this.state = _searchState.$departments;
      this.stateChange = this._searchState.stateEvents.departments.subscribe(
        state => {
          if (!this._searchState.$departments.selectedDepartment && !this.state.departments) {
            this.initSearch();
          }
          if (!!state.people) {
            this.state.people = this.setDepartmentPeople(state.people);
            this.getSubDepts();
          }
          this.state.isLoading = false;
          this._scrollService.scrollTo();
      });
    }

  ngAfterViewInit() {
    // set focus on search if not first time page load
    if (this._settingsService.isSettingsLoaded()) {
      this.deptFilter.nativeElement.focus();
    }
  }

  ngOnInit() {
    this.filterInput.valueChanges.subscribe(text => {
      this.state.sorch.filter = text;
      this.onFilter();
    });

    this.departmentFilterInput.valueChanges.subscribe(text => {
      this.showDepartments = !!text;
      this.state.sorch.departmentFilter = text;
      this.onDepartmentFilter();
    });

    this.departmentTypeFilterInput.valueChanges.subscribe(value => {
      this.state.sorch.departmentTypeFilter = value;
      this.onDepartmentFilter();
    });
    //this._headerService.setHeader();
  }

  ngOnDestroy() {
    this.stateChange.unsubscribe();
  }

  initSearch() {
    this.loadDepartments();
    if (this.state.sorch.departmentFilter) {
      this.showDepartments = true;
      this.departmentFilterInput.setValue(this.state.sorch.departmentFilter);
      this.onDepartmentFilter();
    } else if (!!this.state.searchFields && this.state.searchFields.browseByLetter) {
      this.listByName(this.state.searchFields.browseByLetter);
    }
    if (!this.state.sorch || !this.state.sorch.sortOn) {
      this.state.sorch.sortOn = 'first';
    }
    if (!this.state.sorch.typeFilter) {
      this.state.sorch.typeFilter = 'all';
    }
  }

  loadDepartments() {
    this.isLoadingDepartments = true;
    const alphabetArr = [];
    this._elastic.getAllDepartments().subscribe(results => {
      this.state.departments = this._elastic.mapElasticData(results.hits).map(
        department => {
          department.groupBy = department.indexDescr.toUpperCase().charAt(0);
          if (!alphabetArr.includes(department.groupBy)) {
            alphabetArr.push(department.groupBy);
          }
          department.searchString = `${department.indexDescr.toLowerCase()}${department.n__dept_longdescr.toLowerCase()}`;
          if (Array.isArray(department.plans)) {
            department.plans.forEach(plan => {
              department.searchString += `${plan.diploma_descr.toLowerCase()}`;
            });
            delete department.plans;
          }
          if (Array.isArray(department.n__keywords)) {
            department.n__keywords.forEach(keyWord => {
              department.searchString += `${keyWord.toLowerCase()}`;
            });
            delete department.n__keywords;
          }
          return department;
        });
      this.state.departments.sort((a, b) => {
        return a.indexDescr.toUpperCase() < b.indexDescr.toUpperCase()
          ? -1
          : a.indexDescr.toUpperCase() > b.indexDescr.toUpperCase()
            ? 1 : 0;
      });
      this.state.sorch.departmentAlphaArr = alphabetArr.sort((a, b) => a < b ? -1 : a > b ? 1 : 0);
      this.isLoadingDepartments = false;
    });
  }

  getSubDepts() {
    this._elastic.getDepartmentSubTree(this.state.selectedDepartment.deptid).subscribe(
      tree => {
        this.state.selectedDepartment.subDepartments = tree.children.sort(
          (a, b) => a.n__dept_longdescr < b.n__dept_longdescr ? -1 : a.n__dept_longdescr > b.n__dept_longdescr ? 1 : 0);
      }
    );
  }

  setDepartmentPeople(departmentPeople: any[]) {
    if (!departmentPeople.length) {
      return [];
    }
    const deptMembers = { listed: [], filterCount: 0 };
    const deptAdmins = { listed: [], filterCount: 0 };
    deptMembers.listed = departmentPeople.map(person => {
      let deptJob;
      let deptAltJob;
      if (person.jobs) {
        this._contactservice.parseJobs(person, this.state.selectedDepartment.deptid);
        deptJob = person.jobs.find(job => this.state.selectedDepartment.deptid === job.deptId);
      }
      if (person.departments) {
        if (deptAltJob = person.departments.find(altJob => this.state.selectedDepartment.deptid === altJob.deptid)) {
          if (deptAltJob.roles) {
            for (const role of deptAltJob.roles) {
              person.is_faculty = role === 'FACU';
              person.is_staff = role === 'STAF';
              person.is_gradassistant = role === 'GRAD';
              person.is_affiliate = role === 'AFFL';
            }
          }
        }
      }
      let isFeatured = deptJob && (deptJob.adminStaff === 'Y' || deptJob.adminFaculty === 'Y');
      isFeatured = isFeatured || (deptAltJob && deptAltJob.deptadmin === 'Y');
      if (isFeatured) {
        if (deptAltJob) {
          person.order = deptAltJob.order;
        }
        deptAdmins.listed.push(this._contactservice.parseJobs(person));
      }
      return person;
    });
    this.state.sorch.sortOn = 'last';
    this.state.sorch.typeFilter = 'all';
    deptMembers.listed = this.sortContacts(deptMembers.listed, this.state.sorch.sortOn);
    deptAdmins.listed.sort((a, b) => Number(a.order) - Number(b.order));
    return { members: deptMembers, admins: deptAdmins };
  }

  listByName(letter: string) {
    this.showDepartments = true;
    this.state.searchFields.browseByLetter = letter;
    this.state.sorch.departmentFilter = null;
    this.departmentFilterInput.setValue('', { emitEvent: false });
    this.onDepartmentFilter();
  }

  handleSelect(value: any, preserveResults?: boolean) {
    this._scrollService.scrollTo();
    this._searchState.setNavigation({ id: value.selectedValue.deptid });
  }

  handleSelectById(deptId: string, preserveResults?: boolean) {
    this._scrollService.scrollTo();
    this._searchState.setNavigation({ id: deptId });
  }

  currentView() {
    if (!this.state.selectedDepartment) {
      return 'default';
    } else if (!this.state.selectedValue) {
      return 'department';
    } else {
      return 'person';
    }
  }

  handleSelectPerson(event: any) {
    this._scrollService.scrollTo();
    this.state.isLoading = !this.state.isLoading;

    setTimeout(() => {
      this.state.selectedValue = event.selectedValue;
      const params = {
        id: this.state.selectedDepartment.deptid,
        person: this.state.selectedValue.uid
      };
      if (this.state.source) {
        params['src'] = this.state.source;
      }
      this._searchState.setNavigation(params);
    }, 500);
  }

  showPreviousResults() {
    let params;
    if (this.state.selectedValue) {
      this.state.selectedValue = null;
      params = { id: this.state.selectedDepartment.deptid };
    } else {
      this.state.selectedDepartment = null;
      this.state.people = null;
    }
    if (this.state.source) {
      params['src'] = this.state.source;
    }
    this._searchState.setNavigation(params);
  }

  newSearch() {
    this.newSearchTerm = this.newSearchInput.value;
  }

  clearSearch() {
    this.state.sorch.departmentFilter = null;
    this.departmentFilterInput.setValue('', { emitEvent: false });
    this.showDepartments = false;
  }

  onSort(sortType: string) {
    this.state.sorch.sortOn = sortType;
    this.state.people.members.listed = this.sortContacts(this.state.people.members.listed, sortType || null);
  }

  sortContacts(sortData: any[], sortType: string): any[] {
    const sortField = sortType === 'last' ? 'sn' : 'givenName';
    return [...sortData].sort((a, b) => a[sortField] < b[sortField] ? -1 : a[sortField] > b[sortField] ? 1 : 0);
  }

  onDepartmentFilter() {
    this.allDepartmentsFiltered = true;
    const alphabetArr = [];
    this.state.departments = this.state.departments.map(
      department => {
        department.isFiltered = false;
        if (this.state.sorch.departmentTypeFilter) {
          department.isFiltered =
            (this.state.sorch.departmentTypeFilter === 'academic' && department.academic_dept === 'N')
            || (this.state.sorch.departmentTypeFilter === 'nonacademic' && department.academic_dept === 'Y');
        }
        if (!department.isFiltered && !alphabetArr.includes(department.groupBy)) {
          alphabetArr.push(department.groupBy);
        }
        if (this.state.sorch.departmentFilter) {
          const isSearchMatch = !department.searchString.includes(this.state.sorch.departmentFilter.toLowerCase());
          department.isFiltered = department.isFiltered || isSearchMatch;
        }
        if (!department.isFiltered) {
          this.allDepartmentsFiltered = false;
        }
        return department;
      });
      this.state.sorch.departmentAlphaArr = alphabetArr;
  }

  onFilter(filterType?: string) {
    if (!!filterType) {
      filterType = filterType.replace(' ', '');
    }
    this.allPeopleFiltered = this.state.people.members.listed ? true : false;
    if (filterType) { this.state.sorch.typeFilter = filterType; }
    this.state.people.members.filterCount = 0;
    this.state.people.members.listed = this.state.people.members.listed.map(person => {
          person.isFiltered = this.isFiltered(person);
          if (!person.isFiltered) {
            this.allPeopleFiltered = false;
          } else {
            this.state.people.members.filterCount++;
          }
          return person;
        });
  }

  isFiltered(contact: any) {
    let isFiltered = false;
    // check for employee type
    isFiltered = this.state.sorch.typeFilter !== 'all' && !contact['is_' + this.state.sorch.typeFilter];
    // check for filter input match
    if (!this.state.sorch.filter || isFiltered) { return isFiltered; }
    const term = this.state.sorch.filter.toLowerCase();
    let match = '';
    match += contact.givenName ? contact.givenName.toLowerCase() + ' ' : '';
    match += contact.sn ? contact.sn.toLowerCase() : '';
    isFiltered = !match.includes(term);
    return isFiltered;
  }

  hasPhysicalAddress() {
    const test = this.state.selectedDepartment.n__phys_address1
    + this.state.selectedDepartment.n__phys_city
    + this.state.selectedDepartment.n__phys_state
    + this.state.selectedDepartment.n__phys_postal;
    return !!this.state.selectedDepartment &&
      ((!!this.state.selectedDepartment.n__phys_address1
      || !!this.state.selectedDepartment.n__phys_city
      || !!this.state.selectedDepartment.n__phys_state
      || !!this.state.selectedDepartment.n__phys_postal)
      && test.match(/^ *$/) === null);
  }

  hasMailingAddress() {
    return !!this.state.selectedDepartment &&
      (!!this.state.selectedDepartment.n__mail_address1
      || !!this.state.selectedDepartment.n__mail_city
      || !!this.state.selectedDepartment.n__mail_state
      || !!this.state.selectedDepartment.n__mail_postal);
  }

  hasContactInfo() {
    return !!this.state.selectedDepartment &&
      (!!this.state.selectedDepartment.email_addr
      || !!this.state.selectedDepartment.phone
      || !!this.state.selectedDepartment.url);
  }

  isAlphaArrBreakpoint(index: number): boolean {
    if (this.state.sorch.departmentAlphaArr && this.state.sorch.departmentAlphaArr.length > 11) {
      return index === Math.ceil(this.state.sorch.departmentAlphaArr.length / 2);
    }
    return false;
  }

  focusAlphaIndex() {
    this.alphaIndex.nativeElement.focus();
  }

  refresh() {
    this.departmentFilterInput.setValue('', { emitEvent: false });
    this.state.selectedValue = null;
    this.state.selectedDepartment = null;
    this.state.people = [];
    this._searchState.setNavigation();
  }

}
