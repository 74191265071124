import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { of, forkJoin, Subject, pipe } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';

import { SpaSettingsService } from './spa-settings.service';


@Injectable({
  providedIn: 'root'
})
export class DynamicLayoutService  {


  private header: SafeHtml;
  private breadcrumbs: SafeHtml;
  private cta: SafeHtml;
  private footer: SafeHtml;
  public loader = false;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private spaSettings: SpaSettingsService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    //if (route.queryParams.src) {
    //  return this.getHeaderFooterData(route.queryParams.src);
    //}
   return of(true);
  }

  getHeaderFooterData(src: string): Subject<any> {
    this.loader = true;
    const layoutRequest = new Subject();
    setTimeout(() => {
      layoutRequest.next(true);
      }, 5000);
    this.spaSettings.settingsRequest
      .pipe(
        concatMap(results => {
          const layoutUrl = `${results.wordpressUrl}/wp-json/enterprise/v1/site-permalink/${src}`;
          return this.http.get(layoutUrl, { responseType: 'json' }).pipe(catchError(error => of(error)));
        }),
        concatMap(res => {
          if (!res) {
            return of(null);
          }
          const baseUrl = res.url;
          return this.http.get(`${baseUrl}/wp-json/enterprise/v1/navigation-all/directory `, { responseType: 'json' })
            .pipe(catchError(error => of(error)));
        })
      ).subscribe(results => {
        this.loader = false;
        if (results) {
          this.header = this.sanitizer.bypassSecurityTrustHtml(results.header);
          this.breadcrumbs = this.sanitizer.bypassSecurityTrustHtml(results.breadcrumbs);
          this.cta = this.sanitizer.bypassSecurityTrustHtml(results.cta_buttons);
        }
        layoutRequest.next(true);
      }, error => layoutRequest.next(true));
    return layoutRequest;
  }

  public get $header(): SafeHtml {
    return this.header;
  }

  public set $header(value: SafeHtml) {
    this.header = value;
  }

  public get $breadcrumbs(): SafeHtml {
    return this.breadcrumbs;
  }

  public set $breadcrumbs(value: SafeHtml) {
    this.breadcrumbs = value;
  }

  public get $cta(): SafeHtml {
    return this.cta;
  }

  public set $cta(value: SafeHtml) {
    this.cta = value;
  }

  public get $footer(): SafeHtml {
    return this.footer;
  }

  public set $footer(value: SafeHtml) {
    this.footer = value;
  }

}
