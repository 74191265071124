import { Component, OnInit, Inject, HostListener } from '@angular/core';
//import { DOCUMENT } from "@angular/platform-browser";

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.sass']
})
export class ScrollToTopComponent implements OnInit {
  windowScrolled: boolean;
  constructor() { }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset && document.documentElement.scrollTop > 400 ) {
        this.windowScrolled = true;
    } 
   else if (this.windowScrolled && window.pageYOffset && document.documentElement.scrollTop && document.documentElement.scrollTop < 10) {
        this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
  }
  ngOnInit(): void {
  }

}
