import { Directive, ElementRef, Input, OnDestroy, OnInit, AfterViewInit, AfterViewChecked, Renderer2  } from '@angular/core';
import { fromEvent, Subscription, combineLatest, Observable, of } from 'rxjs';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Directive({
  selector: '[appScrollToElement]'
})
export class ScrollToElementDirective implements OnInit, OnDestroy {

  @Input() appScrollToElement: string;
  @Input() offset: number;
  @Input() mobileOffset: number;
  @Input() mobileScreenMaxSize: number;
  @Input() awaitExec: Observable<string>;
  @Input() setFocus = false;

  clickEvent$: Subscription;
  enterEvent$: Subscription;
  resizeEvent$: Subscription;
  isMobileSize: boolean;

  constructor(private el: ElementRef, private scrollToService: ScrollToService, private renderer: Renderer2) { }

  ngOnInit() {
    this.updateIsMobile();
    this.enterEvent$ = fromEvent(this.el.nativeElement, 'keyup').subscribe(
      (keyEvent: any) => {
        if (keyEvent.key === 'Enter') {
          this.scroll();
        }
      }
    );
    this.clickEvent$ = fromEvent(this.el.nativeElement, 'click').subscribe(
      (clickEvent: any) => {
        this.scroll();
      }
    );
    this.resizeEvent$ = fromEvent(window, 'resize').subscribe(
      (resizeEvent: any) => {
        this.updateIsMobile();
      }
    );
  }


  updateIsMobile() {
    if (this.mobileScreenMaxSize && this.mobileOffset && window.innerWidth < this.mobileScreenMaxSize) {
      this.isMobileSize = true;
    } else {
      this.isMobileSize = false;
    }
  }

  ngOnDestroy() {
    this.enterEvent$.unsubscribe();
    this.clickEvent$.unsubscribe();
    this.resizeEvent$.unsubscribe();
  }

  scroll() {
    const asyncCall = this.awaitExec ? this.awaitExec : of(this.appScrollToElement);
    let target = '';
    let offset = 0;
    asyncCall.subscribe(
      result => {
        setTimeout( () => { // setTimeout "hack" to allow new elements to be rendered before attempting scroll
          const scrollToElement: HTMLElement = document.querySelector('#' + result);
          if (result === 'contactInfo') {
            target = 'contact-result';
          }
          else {
            target = result;
            offset = -65;
          }
          if (scrollToElement) {
            const config: ScrollToConfigOptions = {
              target: target,
              offset: offset
            };
            this.scrollToService.scrollTo(config);
            if (this.setFocus) {
              scrollToElement.focus();
            }
          }
        });
      }
    );
  }

}
