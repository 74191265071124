<div [class]="'page-container ' + activeTab + '-active'">
  <div class="container-fluid" id="tabs-container">
    <div class="row">
      <div class="col-lg col-xl-3 mt-3 col-sm-6">
        <h1 class=" ml-0 ml-xl-3">NAU Directory</h1>
      </div>
      <div class="d-none d-sm-block col-sm-6 col-lg col-xl-3 order-lg-2" >
        <div class="ml-sm-5 ml-lg-0 mt-3 search-container">
         <!--  <nau-search
              #search
              [isCompact]="true"
              (valueSelected)="handleSearchSelectPerson($event, true)"
              (departmentSelected)="handleSearchSelectDepartment($event)"
              (showAll)="handleSearchSelectAll($event)"
              *ngIf="activeTab !== 'all'"></nau-search> -->
        </div>
      </div>
      <div
          class="col-12 col-lg col-xl-6 mt-5 mt-lg-4 d-flex justify-content-center align-items-start align-items-end">
        <!-- <div
            id="search-title"
            class="font-weight-bolder text-nau-bl ue-alt d-none d-sm-block pb-2 mr-2"
            aria-hidden="true">
          <p>Search:</p>
        </div> -->
        <a
            [routerLink]="tab.name"
            class="tab text-capitalize"
            [ngClass]="{ 'active' : !!activeTab && activeTab.startsWith(tab.name) }"
            (click)="navigate($event, tab)"
            *ngFor="let tab of tabData"
            [aria-label]="'navigate to ' + tab.name"
            [tabindex]="!!activeTab && activeTab.startsWith(tab.name) ? -1 : 0">
          <span aria-hidden="true">{{tab.name}}</span>
        </a>
      </div>
    </div>
  </div>
  <hr>
</div>
