import { Component } from '@angular/core';
import { DynamicLayoutService } from './directory/shared/services/dynamic-layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public dynamicLayoutGuard: DynamicLayoutService) {
    document.getElementById('skip-to-content-link').onclick = function (event) {
      event.preventDefault();
      document.getElementById('mainContent').focus();
    };
  }
}
