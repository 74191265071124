import { Directive, Input, ElementRef, ComponentFactoryResolver, ViewContainerRef, AfterViewInit } from '@angular/core';
import { AnimationBuilder, AnimationMetadata, style, animate, AnimationPlayer } from '@angular/animations';
import { LoadingSpinnerComponent } from '../components/loading-spinner/loading-spinner.component';

@Directive({
  selector: '[appShowLoadingAnim]'
})
export class ShowLoadingAnimDirective {

  @Input() hideFooter: boolean;
  @Input() loaderStyle: string;
  @Input() preserveHeight: boolean;
  @Input() absolute: boolean;

  player: AnimationPlayer;
  offset: number;

  constructor(
    private el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private builder: AnimationBuilder,
    private viewContainerRef: ViewContainerRef) {
      this.offset = this.el.nativeElement.offsetHeight;
    }

  @Input() set appShowLoadingAnim(isLoading: boolean) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingSpinnerComponent);
    const footerElem = document.getElementById('js-nau-footer');
    if (isLoading) {
      const spinner = this.viewContainerRef.createComponent(componentFactory);
      spinner.instance.style = this.loaderStyle;
      spinner.instance.absolute = this.absolute;
      if (this.hideFooter) {
        footerElem.style.display = 'none';
      }
    } else {
      if (this.viewContainerRef.length) {
        this.viewContainerRef.remove(0);
      }
    }
    this.el.nativeElement.hidden = isLoading;
    this.animate(isLoading);
  }

  private animate(isLoading: boolean) {
    if (this.player) {
      this.player.destroy();
    }
    const metadata = isLoading ? this.fadeOut() : this.fadeIn();
    const factory = this.builder.build(metadata);
    const player = factory.create(this.el.nativeElement);
    setTimeout(() => { player.play(); });
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate('150ms ease-in', style({ opacity: 1 })),
    ];
  }

  private fadeOut(): AnimationMetadata[] {
    return [
      style({ opacity: '*' }),
      animate('150ms ease-in', style({ opacity: 0 })),
    ];
  }

}
