import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Component
import { AdvanceSearchComponent } from './components/search/advance-search.component';
import { NavComponent } from './components/nav/nav.component';
import { SearchComponent } from './components/search/search.component';

// Services
import { ElasticService } from './services/elastic.service';
import { FacultyService } from './services/faculty.service';
import { LdapService } from './services/ldap.service';
import { MockDataService } from './services/mockdata.service';
import { PeopleSoftService } from './services/peoplesoft.service';
import { SpaSettingsService } from './services/spa-settings.service';
import { SearchStateService } from './services/search-state.service';
import { ContactService } from './services/contact.service';
import { HeaderService } from './services/header.service';

// Pipes
import { FilterPipe } from './pipes/filter.pipes';
import { JoinPipe } from './pipes/join.pipe';
import { MapToKeysPipe } from './pipes/maptokeys.pipe';
import { ObjectKeysPipe } from './pipes/objectKeys.pipes';

import { ResultsTableComponent } from './components/results-table/results-table.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { NgbdSortableTableHeaderDirective } from './directives/ngbd-sortable-table-header.directive';
import { ScrollViewIndicatorDirective } from './directives/scroll-view-indicator.directive';
import { ScrollToElementDirective } from './directives/scroll-to-element.directive';
import { DynamicLayoutComponent } from './components/dynamic-layout/dynamic-layout.component';
import { KeyboardFocusContainerDirective } from './directives/keyboard-focus-container.directive';
import { TelephoneFormatPipe } from './pipes/telephone-format.pipe';
import { UrlFormatPipe } from './pipes/url-format.pipe';
import { ShowLoadingAnimDirective } from './directives/show-loading-anim.directive';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { BreakOnPipe } from './pipes/break-on.pipe';
import { NgbDropdownScrollDirective } from './directives/ngb-dropdown-scroll.directive';
import { NgbTypeaheadSeparatorDirective } from './directives/ngb-typeahead-separator.directive';
import { KeyboardAnchorAccessibilityDirective } from './directives/keyboard-anchor-accessibility.directive';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
// import { DepartmentComponent } from './components/department/department.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    declarations: [
        AdvanceSearchComponent,
        FilterPipe,
        JoinPipe,
        MapToKeysPipe,
        ObjectKeysPipe,
        NavComponent,
        SearchComponent,
        ResultsTableComponent,
        InfoCardComponent,
        NgbdSortableTableHeaderDirective,
        ScrollViewIndicatorDirective,
        ScrollToElementDirective,
        DynamicLayoutComponent,
        KeyboardFocusContainerDirective,
        TelephoneFormatPipe,
        UrlFormatPipe,
        ShowLoadingAnimDirective,
        LoadingSpinnerComponent,
        BreakOnPipe,
        NgbDropdownScrollDirective,
        NgbTypeaheadSeparatorDirective,
        KeyboardAnchorAccessibilityDirective,
        ScrollToTopComponent,
        // DepartmentComponent,
    ],
    exports: [
        AdvanceSearchComponent,
        FilterPipe,
        JoinPipe,
        MapToKeysPipe,
        NavComponent,
        ObjectKeysPipe,
        TelephoneFormatPipe,
        UrlFormatPipe,
        SearchComponent,
        InfoCardComponent,
        ResultsTableComponent,
        ScrollViewIndicatorDirective,
        ScrollToElementDirective,
        DynamicLayoutComponent,
        KeyboardFocusContainerDirective,
        ShowLoadingAnimDirective,
        LoadingSpinnerComponent,
        NgbDropdownScrollDirective,
        NgbTypeaheadSeparatorDirective,
        BreakOnPipe,
        KeyboardAnchorAccessibilityDirective,
        ScrollToTopComponent
        // DepartmentComponent,
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ElasticService,
        FacultyService,
        LdapService,
        MockDataService,
        PeopleSoftService,
        SpaSettingsService,
        SearchStateService,
        HeaderService,
        ContactService,
        BreakOnPipe
      ]
    };
  }
}
