import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakOn'
})
export class BreakOnPipe implements PipeTransform {

  transform(value: string, ...args: string[]): unknown {
    if (value && args[0]) {
      return value.replace(args[0], '<br>');
    }
    return null;
  }

}
