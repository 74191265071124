import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Observable, of, Subscription } from 'rxjs';

// Shared
import { SearchComponent } from './../shared/components/search/search.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('searchRef', { read: ViewContainerRef })
  searchRef: ViewContainerRef;

  genericLookup: ComponentRef<SearchComponent>;

  constructor(private _resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    // this.createSearchDynamicComponent();
  }

  ngOnDestroy() {}

  /* createSearchDynamicComponent() {
    if (this.genericLookup) {
      this.genericLookup.destroy();
    }
    const lookupFactory = this._resolver.resolveComponentFactory(
      SearchComponent
    );
    this.genericLookup = this.searchRef.createComponent(lookupFactory);
    this.genericLookup.instance.selected.subscribe(
      this.handleSelect.bind(this)
    );
  } */

  handleSelect(value: any) {
    // console.log(value);
  }
}
