import { Directive, ElementRef, AfterViewInit, ViewContainerRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNgbTypeaheadSeparator]'
})
export class NgbTypeaheadSeparatorDirective implements AfterViewInit {

  constructor(private el: ElementRef, private viewContainerRef: ViewContainerRef, private _renderer: Renderer2) { }

  ngAfterViewInit() {
    const hostElem = this.el.nativeElement;
    this._renderer.setAttribute(hostElem.parentNode, 'disabled', 'true');
    this._renderer.setAttribute(hostElem.parentNode, 'tabindex', '-1');
    this._renderer.setAttribute(hostElem.parentNode, 'aria-hidden', 'true');
  }
}
