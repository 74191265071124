<app-dynamic-layout [sectionType]="'cta'"></app-dynamic-layout>
<app-dynamic-layout [sectionType]="'header'"></app-dynamic-layout>
<app-dynamic-layout [sectionType]="'breadcrumbs'"></app-dynamic-layout>
<div class="wrapper">
  <!-- <app-nav [activeTab]="'departments'" (refresh)="refresh()" *ngIf="!state.source"></app-nav> -->
  <app-nav [activeTab]="'departments'" (refresh)="refresh()"></app-nav>
  <div class="background">
     <div class="outlet-page" id="content">
       <div [appShowLoadingAnim]="state.isLoading"  loaderStyle="fa-4x my-9">
          <div class="container" [ngClass]="{ 'container-stretch' : state.selectedDepartment }" >
            <ng-template *ngIf="!state.selectedDepartment then default"></ng-template>
            <ng-template *ngIf="state.selectedDepartment && !state.selectedValue then department"></ng-template>
          </div>
          <ng-template *ngIf="state.selectedDepartment && state.selectedValue then person"></ng-template>
       </div>
    </div>
  </div>
</div>

<ng-template #default>
  <div class="row pb-5">
    <div class="col-sm-8 offset-sm-2">
      <h2 class="text-center my-5 ">Search Departments</h2>
      <div class="input-group">
        <div class="input-group-prepend rounded-0">
          <span class="input-group-text rounded-0" id="search-icon" aria-hidden="true">
            <i class="fas fa-search" *ngIf="!isLoading"></i>
            <i class="fas fa-circle-notch fa-spin" *ngIf="isLoading"></i>
          </span>
        </div>
        <input
            #deptFilter
            id="dept-filter-input"
            type="text"
            aria-label="Search departments"
            class="form-control rounded-0"
            placeholder="Search by department name"
            autocomplete="off"
            novalidate
            [formControl]="departmentFilterInput"/>
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" (click)=clearSearch()>
              <i class="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
      <div class="text-center mt-4">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="deptTypeAll" value="all" [formControl]="departmentTypeFilterInput" aria-label="filter by">
          <label class="form-check-label mr-3" for="deptTypeAll">All</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="deptTypeAcademic" value="academic" [formControl]="departmentTypeFilterInput" aria-label="filter by">
          <label class="form-check-label mr-3" for="deptTypeAcademic">Academic</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="deptTypeNonacademic" value="nonacademic" [formControl]="departmentTypeFilterInput" aria-label="filter by">
          <label class="form-check-label mr-3" for="deptTypeNonacademic">Non-academic</label>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isLoadingDepartments">
    <div class="row">
      <div class="col text-center">
        or, browse departments by name:
      </div>
    </div>
    <div class="sticky-top text-center">
      <div class="pt-1 nav-list" #alphaIndex tabindex="-1">
        <ng-container *ngFor="let alpha of this.state.sorch.departmentAlphaArr; let i = index">
          <button
              class="btn btn-link "
              [appScrollToElement]="'deptAlpha-' + alpha"
              [setFocus]="true"
              [offset]="60"
              [mobileOffset]="mobileNavHeight"
              [mobileScreenMaxSize]="navMobileSize"
              (click)="listByName(alpha)"
              aria-label="skip to">
            {{alpha}}
          </button>
          <br class="d-md-none" *ngIf="isAlphaArrBreakpoint(i)"/>
        </ng-container>
        <button
            class="btn btn-link "
            (click)="_scrollService.scrollTo()"
            *ngIf="this.state.sorch.departmentAlphaArr && !!this.state.sorch.departmentAlphaArr.length"
            aria-hidden="true">
          <i class="fas fa-solid fa-arrow-up"></i>
        </button>
      </div>
    </div>
    <div class="row mt-3" *ngIf="showDepartments">
      <div class="col mb-5">
        <ng-container *ngFor="let department of state.departments; let i = index">
          <ng-container *ngIf="!state.sorch.departmentFilter && (i === 0 || i > 0 && state.departments[i-1].groupBy !== department.groupBy)">
            <ng-container *ngIf="!this.state.sorch.departmentFilter && this.state.sorch.departmentAlphaArr.includes(department.groupBy)">
              <h2 [id]="'deptAlpha-' + department.groupBy" class="d-inline" tabindex="-1">
                {{department.groupBy}}
              </h2>
              <button class="btn-index-focus" aria-label="back to index" (click)="focusAlphaIndex()" aria-label="back to index">
                <i class="fas fa-long-arrow-alt-up fa-xs" aria-hidden="true"></i>
              </button>
              <hr>
            </ng-container>
          </ng-container>
          <app-info-card
              [departmentInfo]="department"
              (cardClick)="handleSelect($event, true)"
              *ngIf="!department.isFiltered"></app-info-card>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="row" *ngIf="isLoadingDepartments">
    <div class="col text-center">
      <i class="fas fa-circle-notch fa-spin fa-2x text-nau-trueblue"></i>
    </div>
  </div>
</ng-template>

<ng-template #department>
  <div class="row py-3" *ngIf="!state.source">
    <div class="col-12 col-lg mr-lg-4 mb-1 mb-lg-0" *ngIf="!state.source">
      <!-- <button class="btn btn-sm btn-nau" (click)="showPreviousResults()">
        Department Search
      </button> -->
      <a appKeyboardAnchor
      (click)="showPreviousResults()"
      >
      &lt; Back to search
    </a>
    </div>
  </div>
  <div class="row py-3">
    <div class="col-12 col-lg order-1 order-lg-0">
      <h2>{{state.selectedDepartment.n__dept_longdescr}}</h2>
      <div class="mb-3" *ngIf="state.selectedDepartment.descrlong">{{state.selectedDepartment.descrlong}}</div>
    </div>
  </div>
  <!-- <div class="row pb-5" *ngIf="!state.source"> -->
  <div class="row pb-5" *ngIf="state.people?.admins?.listed.length || hasContactInfo()">
    <div class="col-12 col-md">
      <h4 *ngIf="hasContactInfo()">Contact</h4>
      <div class="row no-gutters mt-2 mt-md-0">
        <div class="col-md-auto mr-3" *ngIf="state.selectedDepartment.email_addr">
          <a href="mailto:{{state.selectedDepartment.email_addr}}" >
            <i class="fas fa-envelope mr-2"></i>{{state.selectedDepartment.email_addr}}
          </a>
        </div>
        <div class="col-md-auto mr-3" *ngIf="state.selectedDepartment.phone">
          <a href="tel:{{state.selectedDepartment.phone}}" >
            <i class="fas fa-phone mr-2"></i>{{ state.selectedDepartment.phone | telephoneFormat }}
          </a>
        </div>
        <div class="col-md-auto" *ngIf="state.selectedDepartment.url">
          <a href="{{state.selectedDepartment.url}}" >
            <i class="fas fa-link mr-2"></i>{{state.selectedDepartment.url | urlFormat}}
          </a>
        </div>
      </div>
      <div class="row no-guttersmt-2 mt-md-0">
        <div class="col-md-auto mr-3" *ngIf="hasPhysicalAddress()">
          <a
              target="_blank" rel="noopener"
              
              [href]="'http://maps.google.com/?q=' + state.selectedDepartment.n__phys_address1 + ' ' + state.selectedDepartment.n__phys_city + ' ' +  state.selectedDepartment.n__phys_state + ' ' +  state.selectedDepartment.n__phys_postal">
            <i class="fas fa-map-marker-alt mr-2"></i>
            <span class="mr-1" *ngIf="state.selectedDepartment.n__phys_address1">
              {{state.selectedDepartment.n__phys_address1}}
            </span>
            <span class="mr-1" *ngIf="state.selectedDepartment.n__phys_city">
              {{state.selectedDepartment.n__phys_city}}
            </span>
            <span class="mr-1" *ngIf="state.selectedDepartment.n__phys_state">
              {{state.selectedDepartment.n__phys_state}}
            </span>
            <span *ngIf="state.selectedDepartment.n__phys_postal">
              {{state.selectedDepartment.n__phys_postal}}
            </span>
          </a>
        </div>
      </div>
      <div class="row no-gutters mt-2 mt-md-0">
        <div class="col-md-auto mr-3" *ngIf="hasMailingAddress()">
          <span class="mr-1" *ngIf="state.selectedDepartment.n__mail_address1">
            {{state.selectedDepartment.n__mail_address1}}
          </span>
          <span class="mr-1" *ngIf="state.selectedDepartment.n__mail_city">
            {{state.selectedDepartment.n__mail_city}}
          </span>
          <span class="mr-1" *ngIf="state.selectedDepartment.n__mail_state">
            {{state.selectedDepartment.n__mail_state}}
          </span>
          <span *ngIf="state.selectedDepartment.n__mail_postal">
            {{state.selectedDepartment.n__mail_postal}}
          </span>
        </div>
      </div>
      <div class="row no-gutters mt-2 mt-md-0">
        <div class="col-md-auto mr-3">
          <div *ngIf="state.selectedDepartment.descr254">
            {{state.selectedDepartment.descr254}}
          </div>
          <span class="mr-1" *ngIf="state.selectedDepartment.bldg_cd">
            Building: {{state.selectedDepartment.bldg_cd}}<span *ngIf="state.selectedDepartment.n__room">, </span>
          </span>
          <span class="mr-1" *ngIf="state.selectedDepartment.n__room">
            Room: {{state.selectedDepartment.n__room}}
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-8 mt-5 mt-lg-0">
      <h4 *ngIf="state.people?.admins?.listed.length">Leadership</h4>
      <table class="table table-borderless bg-transparent mb-3" *ngIf="state.people?.admins?.listed.length">
        <thead>
          <tr class="font-weight-bold">
            <td>Name</td>
            <td>Position</td>
          </tr>
        </thead>
        <tr *ngFor="let person of state.people.admins?.listed">
          <td class='no-break pr-4'>
            <button
                class="btn btn-link text-left admin-link-btn"
                (click)="handleSelectPerson({ selectedValue: person })">
                {{person.givenName}} {{person.sn}}
            </button>
          </td>
          <td class="pb-2">
            <div [innerHTML]="job.title | breakOn:';'" *ngFor="let job of person.jobs" ></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="d-lg-none mb-4 px-3" *ngIf="this.state.selectedDepartment.subDepartments && this.state.selectedDepartment.subDepartments.length">
      <div >
        <h4 >Departments</h4>
      </div>
      <div class="subDeptLinks" *ngFor="let dept of this.state.selectedDepartment.subDepartments">
        <p>
          <a appKeyboardAnchor
          (click)="handleSelectById(dept.deptid)"
          >
            {{dept.n__dept_longdescr}}
          </a>
        </p>
      </div>
      <!-- <div *ngFor="let dept of this.state.selectedDepartment.subDepartments">
        <button
            role="button"
            class="btn btn-link text-decoration-none text-left text-primary font-weight-bolder"
            (click)="handleSelectById(dept.deptid)">
            {{dept.n__dept_longdescr}}
        </button>
      </div> -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8">
      <div class="col-12 my-5 text-center" *ngIf="allPeopleFiltered || !state.people?.members">
        <h2>No <span *ngIf="allPeopleFiltered">filtered</span> results</h2>
      </div>
      <div class="mb-5 mt-5 mt-lg-0" *ngIf="state.people?.members?.listed.length > 0 && !allPeopleFiltered">
        <h4>Faculty &amp; staff</h4>
        <ng-container *ngFor="let person of state.people.members.listed">
          <app-info-card
              [contactInfo]="person"
              (cardClick)="handleSelectPerson($event)"
              *ngIf="!person.isFiltered"></app-info-card>
        </ng-container>
      </div>
    </div>
    <div class="col-lg-4 order-lg-2">
      <div class="px-3 pb-3 mb-3">
        <h4 class="mb-3 text-nau-trueblue">Filter results</h4>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-icon">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
              id="name-filter"
              type="text"
              aria-label="Filter by name"
              class="form-control"
              placeholder="Filter by name"
              autocomplete="off"
              [formControl]="filterInput"/>
          <div class="input-group-append" id="button-addon4">
            <button class="btn btn-primary" type="button" (click)="filterInput.setValue('')" *ngIf="!!filterInput.value">
                <i class="fas fa-times-circle"></i>
            </button>
          </div>
        </div>
        <div class="form-group mt-4">
          <label for="sortByName">Sort names by</label>
          <div class="btn-group d-flex" role="group" aria-label="sort names">
            <button
                id="sortByName"
                type="button"
                class="btn filter-btn btn-sm py-2 text-capitalize w-100"
                (click)="onSort(sortType)"
                [ngClass]="{ 'type-filter-active' : state.sorch.sortOn === sortType }"
                *ngFor="let sortType of [ 'first', 'last' ]">
              {{sortType}}
            </button>
          </div>
        </div>
        <div class="form-group">
          <label>Show</label>
          <div class="btn-group d-flex " role="group" aria-label="filter results">
            <button
                type="button"
                class="btn filter-btn btn-sm py-2 text-capitalize w-100"
                (click)="onFilter('all')"
                [ngClass]="{ 'type-filter-active' : state.sorch.typeFilter === 'all' }">
              All
            </button>
          </div>
          <div class="btn-group d-flex " role="group" aria-label="filter results">
            <button
                type="button"
                class="btn filter-btn btn-sm py-2 text-capitalize w-100"
                (click)="onFilter(filterType)"
                [ngClass]="{ 'type-filter-active' : state.sorch.typeFilter === filterType }"
                *ngFor="let filterType of ['faculty', 'staff']">
              {{filterType}}
            </button>
          </div>
          <div class="btn-group d-flex" role="group" aria-label="filter results">
            <button
                type="button"
                class="btn filter-btn btn-sm py-2 text-capitalize w-100"
                (click)="onFilter(filterType)"
                [ngClass]="{ 'type-filter-active' : state.sorch.typeFilter === filterType.replace(' ', '') }"
                *ngFor="let filterType of [ 'grad assistant', 'affiliate' ]">
              {{filterType}}s
            </button>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-inline-block mt-4" *ngIf="this.state.selectedDepartment.subDepartments && this.state.selectedDepartment.subDepartments.length">
        <div >
          <h4 class="text-nau-trueblue">Departments</h4>
        </div>
        <div class="subDeptLinks" *ngFor="let dept of this.state.selectedDepartment.subDepartments">
          <p>
            <a appKeyboardAnchor
            (click)="handleSelectById(dept.deptid)"
            >
              {{dept.n__dept_longdescr}}
            </a>
          </p>
          <!-- <button
              role="button"
              class="btn btn-link text-decoration-none text-left text-primary font-weight-bolder"
              (click)="handleSelectById(dept.deptid)">
              {{dept.n__dept_longdescr}}
          </button> -->
        </div>
      </div>
    </div>
    
  </div>
</ng-template>

<ng-template #person>
  <div class="contact-container">
    <div class="row py-4 pl-3 pl-md-0">
      <div class="col ">
       <!--  <button class="btn btn-sm btn-nau" (click)="showPreviousResults()">
          Back to department
        </button> -->
        <a appKeyboardAnchor
          (click)="showPreviousResults()"
          >
          &lt; Back to department
        </a>
      </div>
    </div>
    <app-profile
      [contactId]="state.selectedValue.uid"
      [serviceName]="'departments'"></app-profile>
  </div>
</ng-template>
