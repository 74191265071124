
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { of, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';


import { Store } from '~/app/store';

export interface MockData {
  userName: string,
  userDept: string,
  userTitle: string,
  userClassification: string
}
@Injectable()
export class LdapService {

  constructor(
    private _http: HttpClient,
    private _store: Store
  ) { }

}