import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {
  transform(items: any[], fields: string[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!value) {
      return items;
    }
    const result: any = [];
    for (const field of fields) {
      items.filter(singleItem => {
        if (singleItem[field] && singleItem[field].toString().toLowerCase().includes(value.toLowerCase())) {
          result.push(singleItem);
        }
      });
    }

    return result;
  }
}
