import {
  Component,
  Directive,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  ViewChildren,
  QueryList,
  PipeTransform,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap} from 'rxjs/operators';
import { NgbdSortableTableHeaderDirective } from '../../directives/ngbd-sortable-table-header.directive';
import { ContactService } from '../../services/contact.service';
import { SearchStateService } from '../../services/search-state.service';


export type SortDirection = 'asc' | 'desc' | '';
export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Component({
  selector: 'app-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsTableComponent implements OnInit {

  @Input()
  showTypeFilter = true;
  @Input()
  sorch: any;
  @Output()
  valueSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren(NgbdSortableTableHeaderDirective)
  headers: QueryList<NgbdSortableTableHeaderDirective>;
  stateChange: Subscription;
  _people: any[];
  filterInput = new UntypedFormControl('');
  sortFieldInput = new UntypedFormControl('');
  filterCount = 0;
  saveSorch = true;

  constructor(private _contactService: ContactService) { }

  ngOnInit() {
    this.setSorchFromService();
    this.filterInput.valueChanges.subscribe(
      value => {
        this.sorch.filter = value;
        this.onFilter();
      });
    this.sortFieldInput.valueChanges.subscribe(value => {
      this.onSort( {column: value, direction: 'desc'} );
    });
  }

  @Input() set people(people: any[]) {
    this._people = people.map(
      contact => {
        return this._contactService.parseJobs(contact);
      });
      if (!this.saveSorch) {
        this.sorch.filter = '';
        this.sorch.typeFilter = 'all';
        this.sorch.sortOn = '';
      }
      this.saveSorch = false;
    this.filterInput.setValue('');
  }

  sortDirection(colName: string) {
    return this.sorch.sortOn === colName ? this.sorch.sortDirection : '';
  }

  setSorchFromService() {
    if (!this.sorch.typeFilter) {
      this.sorch.typeFilter = 'all';
    } else {
      this.onFilter(this.sorch.typeFilter);
    }
    if (!!this.sorch.sortOn) {
      const sortParams = {
        column: this.sorch.sortOn,
        direction: this.sorch.sortDirection || '' };
      this.onSort(sortParams);
    }
    this.filterInput.setValue(this.sorch.filter || '', { emitEvent: false });
  }

  onFilter(typeFilter?: string) {
    if (typeFilter) {
      this.sorch.typeFilter = typeFilter;
    }
    this.filterCount = 0;
    this._people = this._people.map(
      person => {
        person.isFiltered = this.isFiltered(person);
        if (!!person.isFiltered) {
          this.filterCount++;
        }
        return person;
      });
  }

  onSort({ column, direction }: SortEvent, init?: boolean) {
    this.sorch.sortOn = column;
    this.sorch.sortDirection = direction;
    this._people = this.sort(this._people, column, direction);
  }

  sort(sortData: any[], column: string, direction: string): any[] {
    let sortResult = sortData;
    if (direction !== '') {
      sortResult = [...sortData].sort((a, b) => {
        const res = a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
        return direction === 'asc' ? res : -res;
      });
    } else { // return to default elastic sort
     sortResult = [...sortData].sort((a, b) => {
        return a.elasticSort[0] > b.elasticSort[0]
          ? -1 : a.elasticSort[0] < b.elasticSort[0]
          ? 1  : a.elasticSort[1] < b.elasticSort[1]
          ? -1 : a.elasticSort[1] > b.elasticSort[1]
          ? 1  : a.elasticSort[2] < b.elasticSort[2]
          ? -1 : a.elasticSort[2] > b.elasticSort[2]
          ? 1  : 0;
      });
    }
    return sortResult;
  }

  isFiltered(contact: any) {
    let isFiltered = false;
    // filter on employee type
    isFiltered =
      this.sorch.typeFilter !== 'all'
      && !contact['is_' + this.sorch.typeFilter];
    if (!isFiltered && this.sorch.filter) {
      const term = this.sorch.filter.toLowerCase();
      // filter on name
      isFiltered = !contact.givenName.toLowerCase().includes(term) && !contact.sn.toLowerCase().includes(term);
      // filter on title
      for (let i = 0; i < contact.jobs.length; i++) {
        if (contact.jobs[i].title.toLowerCase().includes(term)) {
          isFiltered = false;
          return;
        }
      }
      // filter on department descr
      if (contact.jobs.length && contact.jobs[0].deptDescr.toLowerCase().includes(term)) {
        isFiltered = false;
      }
    }
    return isFiltered;
  }

  handlePersonSelected(person: any, event?: any) {
    if (!event || (event && event.key === 'Enter')) {
      this.valueSelected.emit({ selectedValue: person });
    }
  }
}
