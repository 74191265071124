<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Session Timeout</h4>
</div>
<div class="modal-body">
  <p>
    You have been logged out.
    <br><br>
    Click "OK" to refresh.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-nau" (click)="submit()">OK</button>
</div>
