
 <div class="row">
  <div class="col">
    <h4 >
      <span aria-live="assertive" class="assistive-text">
        <span *ngIf="filterCount > 0">{{_people?.length - filterCount}} of </span>{{_people?.length}} results for people
      </span>
    </h4>
  </div>
</div>

<div >
  <form>
    <div class="row align-items-center mb-lg-3 pt-3" id="form-row">
      <div class="col-12 col-md-6 col-lg-6 pb-md-3 pb-lg-0">
        <div class="btn-group d-flex d-lg-inline-block px-1 px-md-4 px-lg-3 align-top" role="group" aria-label="nau role filter" *ngIf="showTypeFilter">
          <button
              type="button"
              class="btn btn-secondary btn-sm text-nau-trueblue bg-white text-capitalize flex-fill px-md-3 px-lg-5"
              (click)="onFilter(filter)"
              [ngClass]="{ 'btn-active' : sorch.typeFilter === filter }"
              *ngFor="let filter of [ 'all', 'faculty', 'staff' ]">
            {{filter}}
          </button>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5 offset-lg-1 pb-3 pb-lg-0">
        <div id="filterGroup" class="input-group px-1 px-md-4">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-icon">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input
              id="filter-input"
              type="text"
              aria-label="Search departments"
              class="form-control form-control-sm"
              placeholder="Filter by name, subject, etc."
              autocomplete="off"
              novalidate
              [formControl]="filterInput"/>
        </div>
      </div>
    </div>
  </form>
  <table class="table table-striped text-left search-results" aria-labelledby="search results">
    <thead>
      <tr>
        <th
            scope="col"
            class="tbl-first-name sortable text-center text-sm-left"
            appNgbdSortableTableHeader="givenName"
            [direction]="sortDirection('givenName')"
            (sort)="onSort($event)">
          First <span class="d-none d-md-inline-block">Name</span>
        </th>
        <th
            scope="col"
            class="tbl-last-name sortable text-center text-sm-left"
            appNgbdSortableTableHeader="sn"
            [direction]="sortDirection('sn')"
            (sort)="onSort($event)">
          Last <span class="d-none d-md-inline-block">Name</span>
        </th>
        <th
            scope="col"
            class="tbl-title sortable d-none d-sm-table-cell"
            appNgbdSortableTableHeader="title"
            [direction]="sortDirection('title')"
            (sort)="onSort($event)">
          Title
        </th>
        <th
            scope="col"
            class="tbl-department sortable d-none d-sm-table-cell"
            appNgbdSortableTableHeader="departmentDescr"
            [direction]="sortDirection('departmentDescr')"
            (sort)="onSort($event)">
          Department
        </th>
        <th scope="col" class="tbl-email d-none d-lg-table-cell">
          Email
        </th>
        <th scope="col" class="tbl-phone d-none d-lg-table-cell">Phone</th>
      </tr>
      <tr class="d-none">
        <th
            scope="col"
            class="tbl-title sortable text-center"
            appNgbdSortableTableHeader="title"
            [direction]="sortDirection('title')"
            (sort)="onSort($event)">
        Title
      </th>
      <th
          scope="col"
          class="tbl-department sortable text-center"
          appNgbdSortableTableHeader="departmentDescr"
          [direction]="sortDirection('departmentDescr')"
          (sort)="onSort($event)">
        Department
      </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let contact of _people">
        <tr class="row-btn" *ngIf="!contact.isFiltered" role="button" (click)="handlePersonSelected(contact)" (keydown)="handlePersonSelected(contact, $event)" tabindex="0">
          <td class="td-full d-none d-lg-table-cell">{{ contact.givenName }}</td>
          <td class="td-full d-none d-lg-table-cell">{{ contact.sn }}</td>
          <td class="td-full d-none d-lg-table-cell">
            <ng-container *ngFor="let job of contact.jobs; let i = index">
              <div [ngClass]="{ 'job-secondary': i > 0 }">
                <span [innerHTML]="job.title | breakOn:';'"></span>
                <span [ngClass]="{ 'job-secondary': i > 0 }" *ngIf="i > 0 && job.deptDescr">; {{ job.deptDescr }}</span>
              </div>
            </ng-container>
          </td>
          <td class="td-full d-none d-lg-table-cell">
            <div *ngIf="contact.jobs">{{ contact.jobs[0]?.deptDescr }}</div>
          </td>
          <td class="td-full d-none d-lg-table-cell">
            {{ contact.nauEduWorkMail }}
          </td>
          <td class="td-full d-none d-lg-table-cell td-phone">
            {{ contact.naueduworkphone | telephoneFormat }}
          </td>
          <td class="td-card d-lg-none" colspan="4">
            <div>{{ contact.givenName }} {{ contact.sn }} </div>
            <ng-container *ngFor="let job of contact.jobs; let i = index">
              <div [ngClass]="{ 'job-secondary': i > 0 }">
                <span [innerHTML]="job.title | breakOn:';'"></span>
                <span [ngClass]="{ 'job-secondary': i > 0 }" *ngIf="i > 0 && job.deptDescr">; {{ job.deptDescr }}</span>
              </div>
            </ng-container>
            <div *ngIf="contact.jobs">{{ contact.jobs[0]?.deptDescr }}</div>
            <div>{{ contact.nauEduWorkMail }}</div>
            <div>{{ contact.naueduworkphone | telephoneFormat }}</div>
          </td>
        </tr>
      </ng-container>
      <tr class="text-center p-2" *ngIf="_people?.length - filterCount === 0">
        <td colspan="6" >No filtered results</td>
      </tr>
    </tbody>
  </table>
</div>
