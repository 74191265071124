import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './directory/home/home.component';

// Modules
import { AuthModule } from './auth/auth.module';
import { DirectoryModule } from './directory/directory.module';
import { ErrorModule } from './error/errorModule';
import { Store } from './store';
import { SharedModule } from './directory/shared/shared.module';
import { ApiInterceptor } from './error/interceptors/httpConfig.interceptor';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./directory/directory.module').then(m => m.DirectoryModule)
  },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
   { path: '**', loadChildren: () => import('./error/errorModule').then(m => m.ErrorModule) },
  // { path: '**', component: ErrorModule, pathMatch: 'full' }
  // { path: 'error', loadChildren: () => import('./error/errorModule').then(m => m.ErrorModule) }
];

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,

    FormsModule,
    RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'disabled' }),
    ScrollToModule.forRoot(),
    NgbModule,
    AuthModule,
    DirectoryModule,
    // ErrorModule,
    SharedModule,
  ],
  declarations: [AppComponent, HomeComponent],
  providers: [
    Store,
    // { provide: 'authenticationUrl', useValue: 'api/auth/IsAuthenticated' },
    { provide: 'authUserUrl', useValue: 'api/auth/authUser' },
    // { provide: 'loginUrl', useValue: '/login?returnUrl=/admin' },
    { provide: 'loginOutUrl', useValue: '' },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
