
<div [ngClass]="{ 'wrapper': isStandAlone }">
  <app-nav [activeTab]="activeTab" [refreshOverride]="true" (refresh)="refresh()" *ngIf="isContactLoaded && isStandAlone"></app-nav>
  <div role="main" class="outlet-page mt-0 mt-md-5" tabindex="-2" id="mainContent">
    <ng-template [ngIf]="isContactLoaded" [ngIfElse]="loading">
      <ng-template [ngIf]="!isContactValid" [ngIfElse]="mainContent">
        <div class="col-12 my-5 text-center">
          <h2>User Was Not Found</h2>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>

<app-dynamic-layout [sectionType]="'footer'" *ngIf="isStandAlone"></app-dynamic-layout>
<ng-template #mainContent>
  <section
      class="main-info-section"
      appScrollViewIndicator
      (isInScrollView)="scrollViewIndicator = $event"
      [mobileScreenMaxSize]="mobileScreenMaxSize"
      [mobileOffset]="0"
      id="contactInfo">
    <div class="contact-container py-3 px-3 py-md-5 px-md-5 no-gutters row" >
      <div class="col-12 col-md-4 col-lg-3 col-xl-3 mx-xl-0 text-center text-md-left"
      [ngClass]="{ 'noimage' : !_contact.image }">
        <img
            [src]="_contact.image.src"
            id="directoryPhoto"
            [alt]="'directory photo for ' +  _contact.givenName + ' ' + _contact.sn"
            class="bg-nau-trueblue"
            *ngIf="!isImageLoading && _contact.image">
        <i id="altUserPhoto" class="fas fa-user fa-8x text-nau-trueblue" *ngIf="!_contact.image"></i>
      </div>
      <div class="col-12 col-md-8 col-lg-5 col-xl-4 mt-4 mt-md-0 pr-2 department-info">
        <h2>
          {{_contact.givenName}}
          <span *ngIf="_contact.middlename">{{_contact.middlename}} </span>{{_contact.sn}}
          <small *ngIf="_contact.pronouns">(<a [href]="_spaSettings.pronounDocUrl" target="blank" >{{_contact.pronouns}}</a>)</small>
        </h2>

        <div class="mt-3 " *ngFor="let job of _contact.jobs">
          <span class="text-heavy" [innerHTML]="job.title | breakOn:';'"></span>
          <div class="mt-1">
            <a routerLink="/departments" [queryParams]="{ id: job.deptId }" target="_blank" rel="noopener">{{job.deptDescr}}</a>
          </div>
        </div>
        <div class="text-heavy mt-2" *ngIf="_contact.secondaryJobs?.length">Also associated with</div>
        <div class="mt-2 " *ngFor="let secondayJob of _contact.secondaryJobs">
          <a routerLink="/departments" [queryParams]="{ id: secondayJob.deptid }" target="_blank" rel="noopener">{{secondayJob.descr}}</a>
        </div>
        <div class="mt-3" *ngIf="_contact.shortbio">
          {{_contact.shortbio}}
        </div>
      </div>
      <div
          class="col-12 col-md-12  col-xl-5 mt-4 mt-lg-0"
          [ngClass]="(!_contact.image) ? 'col-lg-5 offset-lg-2  offset-xl-3' : 'col-lg-4' "
          *ngIf="!!_contact.naueduworkphone || !!_contact.nauEduWorkMail || !!_contact.building || !!_contact.room">
        <div class="border contact-info-section">
          <div id="contactHeader" class=" p-2 clearfix">
            <ng-template #tipContent let-greeting="greeting">{{copyLinkTip}}</ng-template>
            <h4 class="ml-2 mt-2 float-left">Contact</h4>
            <button #profileLink class="btn btn-link btn-sm float-right" (click)="copyProfileLink()" [ngbTooltip]="tipContent" placement="left" (blur)="resetProfileLinkTip()" #profileTip="ngbTooltip">
              <i class="fas fa-link"></i>
            </button>
          </div>
          <div class="p-3">
            <div *ngIf="_contact.naueduworkphone"><a  href="tel:{{_contact.naueduworkphone}}">{{_contact.naueduworkphone | telephoneFormat}}</a></div>
            <div *ngIf="_contact.nauEduWorkMail"><a  href="mailto:{{_contact.nauEduWorkMail}}">{{_contact.nauEduWorkMail}}</a></div>
            <span *ngIf="_contact.building">
              Building:
            </span><span class="d-inline-block" *ngIf="_contact.building">{{_contact.building}}<span *ngIf="_contact.bldg_cd">
              ({{_contact.bldg_cd}})
            </span> <a
                  [href]="'https://www.google.com/maps/search/?api=1&query=' + _contact.latitude + ',' + _contact.longitude"
                  target="_blank" rel="noopener"
                  (click)="$event.stopPropagation()"
                  *ngIf="!!_contact.latitude && !!_contact.longitude">
                <i class="fas fa-map-marker-alt"></i>
              </a>
            </span>
            <div *ngIf="_contact.room">
              Room: {{_contact.room}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="row no-gutters info-section-container bg-white pt-4 px-4 px-xl-0" *ngIf="_contact.hasInfo">
    <div class="col-12 d-none d-lg-block px-3 mb-3" >
      <hr />
    </div>
    <div class="col-12 sticky-top d-lg-none">
      <div class="navbar navbar-expand-lg navbar-light bg-nau-trueblue mb-3 pr-lg-2" ngbDropdown>
          <ul class="nav navbar-nav">
              <button
                  id="navDrop"
                  class="btn btn-nau-tertiary"
                  type="button"
                  aria-label="Toggle navigation"
                  ngbDropdownToggle>
              ON THIS PAGE <!-- <i class="fas fa-bars"></i> -->
            </button>
          </ul>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup" aria-labelledby="navDrop" ngbDropdownMenu>
          <div class="navbar-nav">
            <ng-container *ngTemplateOutlet="navLinks"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 faculty-info-nav d-none d-lg-block">
      <div class="sticky-top mr-lg-3 mr-xl-4 ml-3">
        <ng-container *ngTemplateOutlet="navLinks"></ng-container>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-9 col-xl-8 faculty-info-section" >
      <section class="info-section" id="biography"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.biography"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Biography</h4>
        <div [innerHTML]="_contact.infoSections.biography"></div>
      </section>
      <section class="info-section"  id="degrees"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.degrees"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Degrees</h4>
        <div class="mt-4" *ngFor="let degreeInfo of _contact.infoSections.degrees">
          <h5 [innerHTML]="degreeInfo.title"></h5>
          <div [innerHTML]="degreeInfo.granting_institution"></div>
        </div>
      </section>
      <section class="info-section" id="experience"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.experience"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Experience</h4>
        <div class="mt-4" *ngFor="let experienceInfo of _contact.infoSections.experience">
          <h5 [innerHTML]="experienceInfo.position"></h5>
          <div [innerHTML]="experienceInfo.organization"></div>
          <div class="mt-2">{{experienceInfo.start_year}} &ndash; {{experienceInfo.end_year || 'Present'}}</div>
        </div>
      </section>
      <section
          id="scholarship"
          class="info-section"
          appScrollViewIndicator
          (isInScrollView)="scrollViewIndicator = $event"
          [mobileScreenMaxSize]="mobileScreenMaxSize"
          [mobileOffset]="mobileNavHeight"
          [offset]="offset"
          *ngIf="!!_contact.infoSections.scholarship">
        <h4>Scholarship and Creative Activity</h4>
        <div class="mt-4" *ngFor="let publicationGroup of _contact.infoSections.scholarship">
          <h5>{{publicationGroup.name}}</h5>
          <div class="mt-4" *ngFor="let publication of publicationGroup.productions; let i = index">
            <div [innerHTML]="publication.citation"></div>
            <div *ngIf="!!publication.description">
              <button
                  type="button"
                  class="btn btn-publication-descr btn-link btn-sm text-decoration-none ml-3"
                  (click)="publication.isCollapsed = !publication.isCollapsed"
                  [attr.aria-controls]="'publication-' + i + '-collapse'"
                  [attr.aria-label]="publication.isCollapsed ? 'more info' : 'less info'">
                <span *ngIf="!!publication.isCollapsed" aria-hidden="true"><i class="fas fa-caret-right"></i> Show Abstract</span>
                <span *ngIf="!publication.isCollapsed" aria-hidden="true"><i class="fas fa-caret-down"></i> Hide Abstract</span>
              </button>
              <div
                  id="{{'publication-' + i + '-collapse'}}"
                  class="p-4 publication-extra"
                  [ngbCollapse]="publication.isCollapsed"
                  [innerHTML]="publication.description"></div>
            </div>
          </div>
        </div>
      </section>
      <section
          id="grants"
          class="info-section"
          appScrollViewIndicator
          (isInScrollView)="scrollViewIndicator = $event"
          [mobileScreenMaxSize]="mobileScreenMaxSize"
          [mobileOffset]="mobileNavHeight"
          [offset]="offset"
          *ngIf="!!_contact.infoSections.grants">
        <h4>Grants and Contracts</h4>
        <ul >
          <li class="mt-4" *ngFor="let grant of _contact.infoSections.grants" [innerHTML]="grant.citation"></li>
        </ul>
      </section>
      <section class="info-section" id="programs"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.programs"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Program Affiliation</h4>
        <ul >
          <li class="mt-4" *ngFor="let program of _contact.infoSections.programs">
            <h5>{{program.diploma_descr}}</h5>
            {{program.descrformal}}
          </li>
        </ul>
      </section>
      <section
          id="interests"
          class="info-section"
          appScrollViewIndicator
          (isInScrollView)="scrollViewIndicator = $event"
          [mobileScreenMaxSize]="mobileScreenMaxSize"
          [mobileOffset]="mobileNavHeight"
          [offset]="offset"
          *ngIf="!!_contact.infoSections.interests">
        <h4>Interests</h4>
        <div class="mt-4">
          <ng-template [ngIf]="!!_contact.infoSections.interests?.teaching.length">
            <h5>Teaching</h5>
            <ul >
              <li *ngFor="let interestInfo of _contact.infoSections.interests.teaching" [innerHTML]="interestInfo"></li>
            </ul>
          </ng-template>
          <ng-template [ngIf]="!!_contact.infoSections.interests?.research.length">
            <h5>Research</h5>
            <ul >
              <li *ngFor="let interestInfo of _contact.infoSections.interests.research" [innerHTML]="interestInfo"></li>
            </ul>
          </ng-template>
        </div>
      </section>
      <section class="info-section"  id="certifications"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.certifications"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Certifications & Licensures</h4>
        <div class="mt-4" *ngFor="let certInfo of _contact.infoSections.certifications">
          <h5 [innerHTML]="certInfo.title + ' (' + certInfo.year_conferred + ')'"></h5>
          <div [innerHTML]="certInfo.organization"></div>
        </div>
      </section>
      <section class="info-section"  id="honors"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.honors"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Honors</h4>
        <div class="mt-4" *ngFor="let honorsInfo of _contact.infoSections.honors">
          <h5 [innerHTML]="honorsInfo.title + ' (' + honorsInfo.year_conferred + ')'"></h5>
          <div [innerHTML]="honorsInfo.organization"></div>
          <div [innerHTML]="honorsInfo.description"></div>
        </div>
      </section>
      <section class="info-section"  id="membership"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.membership"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Membership</h4>
        <div class="mt-4" *ngFor="let membershipInfo of _contact.infoSections.membership">
          <h5 [innerHTML]="membershipInfo.organization_name"></h5>
          <div>{{membershipInfo.yearLabel}}</div>
        </div>
      </section>
      <section class="info-section" id="courses"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.courses"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Courses</h4>
        <div class="mt-4" *ngFor="let courseGroup of _contact.infoSections.courses">
          <h5>
            {{courseGroup.n__term_descr}}
          </h5>
          <div *ngFor="let course of courseGroup.courses">
            {{course.subject}} {{course.catalog_nbr}}: {{course.course_title_long}}
            <div class="course-fdescr ml-4" *ngIf="!!course.n__topic_fdescr">
              {{course.n__topic_fdescr}}
            </div>
          </div>
        </div>
        <div *ngIf="!!_contact.infoSections.hiddenCourseGroups?.length">
          <button
              type="button"
              class="btn past-courses btn-link btn-sm text-decoration-none mt-3"
              (click)="isHiddenCoursesCollapsed = !isHiddenCoursesCollapsed"
              aria-controls="hiddenCoursesCollapse"
              *ngIf="isHiddenCoursesCollapsed">
            Show more
          </button>
          <div id="hiddenCoursesCollapse" [ngbCollapse]="isHiddenCoursesCollapsed">
            <div class="mt-4" *ngFor="let courseGroup of _contact.infoSections.hiddenCourseGroups">
              <h5>
                {{courseGroup.n__term_descr}}
              </h5>
              <div *ngFor="let course of courseGroup.courses">
                {{course.subject}} {{course.catalog_nbr}}: {{course.course_title_long}}
                <div class="course-fdescr ml-4" *ngIf="!!course.n__topic_fdescr">
                  {{course.n__topic_fdescr}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="info-section"  id="advisingDegrees"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.advisingDegrees"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Advising Degrees</h4>
        <div class="mt-4" *ngFor="let advisingInfo of _contact.infoSections.advisingDegrees">
          <div>{{advisingInfo.planDescr}}</div>
        </div>
      </section>
      <section class="info-section"  id="advisingSpecialties"
          appScrollViewIndicator (isInScrollView)="scrollViewIndicator = $event" *ngIf="!!_contact.infoSections.advisingSpecialties"
          [mobileScreenMaxSize]="mobileScreenMaxSize" [mobileOffset]="mobileNavHeight" [offset]="offset">
        <h4>Advising Specialties</h4>
        <div class="mt-4" *ngFor="let advisingInfo of _contact.infoSections.advisingSpecialties">
          <div>{{advisingInfo.specialtyDescr}}</div>
        </div>
      </section>
    </div>
  </div>
</ng-template>

<ng-template #navLinks>
    <ul id="profileNav" class="list-unstyled pb-3" appKeyboardFocusContainer>
      <li class="ml-2 ml-xl-4">
        <h6 class="mb-3 mt-4 d-none d-lg-block">
        On this page
          
          <!-- <a
              class="text-capitalize "
              
              [appScrollToElement]="'contactInfo'"
              [mobileScreenMaxSize]="mobileScreenMaxSize"
              [mobileOffset]="mobileNavHeight"
              #keyFocus>
            On this page
          </a> -->
        </h6>
      </li>
      <ng-container *ngFor="let sectionName of ['biography', 'degrees', 'experience', 'scholarship', 'grants', 'programs', 'interests', 'certifications', 'honors', 'membership', 'courses', 'advisingDegrees', 'advisingSpecialties']">
        <li *ngIf="!!_contact.infoSections[sectionName]"  class="ml-5 ml-xl-5 my-1" [ngClass]="{ 'text-nau-trueblue font-weight-bold' : scrollViewIndicator === sectionName }">
          <a
              class=" text-capitalize btn btn-link page-nav"
              [appScrollToElement]="sectionName"
              [mobileScreenMaxSize]="mobileScreenMaxSize"
              [mobileOffset]="mobileNavHeight"
              [offset]="offset"
              #keyFocus>
            <ng-container [ngSwitch]="sectionName">
              <span *ngSwitchCase="'programs'" [ngClass]="{ 'font-weight-bold' : scrollViewIndicator === sectionName }">
                Program Affiliation
              </span>
              <span *ngSwitchDefault [ngClass]="{ 'font-weight-bold' : scrollViewIndicator === sectionName }">
                {{ sectionName === 'advisingDegrees' ? 'Advising Degrees' : sectionName === 'advisingSpecialties' ? 'Advising Specialties' : sectionName}}
              </span>
            </ng-container>
          </a>
        </li>
      </ng-container>
      <li class="ml-5 ml-xl-5 back-to-top">
        <a
            class=" text-capitalize "
            
            [appScrollToElement]="'contactInfo'"
            [mobileScreenMaxSize]="mobileScreenMaxSize"
            [mobileOffset]="mobileNavHeight"
            [offset]="offset"
            #keyFocus>
          Back to top 
        </a>
      </li>
    </ul>
</ng-template>

<ng-template #loading>
  <div class="col-12 py-5 text-center loader-container">
    <i class="fas fa-circle-notch fa-spin fa-4x text-nau-trueblue"></i>
  </div>
</ng-template>
