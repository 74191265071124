import { Directive, ElementRef, HostListener, HostBinding, Input, Output, EventEmitter, OnInit } from '@angular/core';


export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: '[appNgbdSortableTableHeader]'
})
export class NgbdSortableTableHeaderDirective {

  @Input() appNgbdSortableTableHeader: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  @HostListener('click', ['$event.target']) onClick(header) {
    this.rotate();
  }

  @HostBinding('class.asc')
  public get isAscending(): boolean {
    return this.direction === 'asc';
  }

  @HostBinding('class.desc')
  public get isDecending(): boolean {
    return this.direction === 'desc';
  }

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.appNgbdSortableTableHeader, direction: this.direction });
  }

}
