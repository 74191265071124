<div class="wrapper">
  <app-nav [activeTab]="'staff'" (refresh)="refresh()"></app-nav>
  <div class="outlet-page pb-5" id="content">
    <div class="container search-container pb-3 pb-md-5">
      <div class="row mb-4">
        <div class="col-sm-8 offset-sm-2">
          <h2 class="mt-5 mb-2 ">Meet our staff</h2>
          <p>
            There are over 800 employees througout the NAU system who are committed to helping students achieve their career goals.
          </p>
        </div>
      </div>
      <div class="row" *ngIf="!showResults" [appShowLoadingAnim]="isLoading">
        <div class="col-sm-8 offset-sm-2">
          <nau-advance-search searchType="staff" [initSearch]="state.searchFields" (directorySet)="onSearchComplete($event)" [showSpinner]="true" [isFocused]="isSearchFocused"></nau-advance-search>
        </div>
      </div>
      <div class="row" *ngIf="showResults">
        <div class="col-md-9">
          Results for
          <span *ngIf="state.searchFields.title"> {{ state.searchFields.title }}</span>
          <span *ngIf="state.searchFields.name"> {{ state.searchFields.name }}</span>
          <span *ngIf="state.searchFields.department"> in {{ state.searchFields.department }}</span>
        </div>
        <div class="col-md-3" *ngIf="!state.selectedValue">
          <!-- <button class="btn btn-sm btn-nau" (click)="previousView()">
            Back to Search
          </button> -->
          <a appKeyboardAnchor
          (click)="previousView()"
          >
          &lt; Back to search
        </a>
        </div>
      </div>
    </div>
    <div *ngIf="showResults" [appShowLoadingAnim]="isLoading">
      <div class="container">
        <div class="row" *ngIf="!state.selectedValue">
          <div class="col" *ngIf="!state.selectedValue && state.people?.length > 1 && state.people?.length <= 10">
            <h4 class="text-nau-monsoon-darker mb-3">
              {{state.people?.length}} results for people
            </h4>
            <app-info-card [contactInfo]="result" (cardClick)="handleSelectPerson($event)" *ngFor="let result of state.people"></app-info-card>
          </div>
          <div class="col text-center" *ngIf="!hasResults">
            <h3>
              No Results
            </h3>
          </div>
        </div>
        <ng-container *ngIf="!state.selectedValue && hasResults && state.people?.length > 10">
          <app-results-table
              [showTypeFilter]="false"
              [people]="state.people"
              [sorch]="state.sorch"
              (valueSelected)="handleSelectPerson($event)"></app-results-table>
        </ng-container>
      </div>
      <ng-container *ngIf="state.selectedValue">
        <div id="contact-result" class="contact-container mb-3" >
          <!-- <button class="btn btn-sm btn-nau my-3" (click)="previousView()">
            Back to Search
          </button> -->
          <a appKeyboardAnchor
          class="mx-3"
          (click)="previousView()"
          >
          &lt; Back to search results
        </a>
        </div>
        <app-profile [contactId]="state.selectedValue.uid"  [serviceName]="'staff'"></app-profile>
      </ng-container>
    </div>
  </div>
</div>
