
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { of, Subject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';

import { PeopleSoftService } from './peoplesoft.service';


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private _http: HttpClient, private _sanitizer: DomSanitizer) {  }

  getImage(uid: string) {
    return this._http.get(`/api/image/directoryImg/${uid}`, { responseType: 'blob' });
  }

  createImageUrl(file: any): any {
    const urlCreator = window.URL;
    return this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(file));
  }

  parseJobs(contact: any, departmentId?: string): any {
    // clean up empty object properties
    _.forIn(contact, (value, key) => {
      if (typeof value === 'string' && !value.trim()) {
        contact[key] = '';
      }
    });
    contact.directoryJobInfo = contact.directoryJobInfo || [];
    if (!contact.isJobsSorted) {
      if (!contact.jobs) {
        contact.jobs = [];
      }
      if (contact.affiliations) {
        contact.jobs = contact.jobs.concat(contact.affiliations);
        if (contact.jobs.length && contact.jobs.length === contact.affiliations.length) {
          contact.jobs[0].primaryJob = 'Y';
        }
      }
      const primaryJob = contact.jobs.find(j => j.primaryJob === "Y") || contact.jobs[0];
      if (primaryJob) {
        contact.title = primaryJob.title;
        contact.departmentDescr = primaryJob.deptDescr;
        contact.departmentNumber = primaryJob.deptId;
      }
      contact.hasMultipleDepartments = false;
      let jobsDepartment = null;
      contact.jobs = contact.jobs.map(
        job => {
          // track for jobs in different departments
          if (!jobsDepartment) {
            jobsDepartment = job.deptId;
          } else if (jobsDepartment !== job.deptId) {
            contact.hasMultipleDepartments = true;
          }
          return job;
        });
      if (departmentId) {
        contact.jobs = contact.jobs.sort((a, b) => {
          const result = a.deptId === departmentId && b.deptId !== departmentId ? -1
            : a.deptId !== departmentId && b.deptId === departmentId ? 1
            : a.primaryJob === 'Y' && b.primaryJob !== 'Y' ? -1
            : 0;
          return result;
        });
      }
      contact.isJobsSorted = true;
    }
    return contact;
  }

}
