import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, HostListener } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[appScrollViewIndicator]'
})
export class ScrollViewIndicatorDirective implements OnDestroy, OnInit {

  @Input() mobileOffset: number;
  @Input() mobileScreenMaxSize: number;
  @Output() isInScrollView: EventEmitter<string> = new EventEmitter<string>();

  scrollEvent$: Subscription;
  resizeEvent$: Subscription;
  isMobileSize: boolean;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.updateIsMobile();
    this.scrollEvent$ = fromEvent(window, 'scroll').subscribe(
      (scrollEvent: any) => {
        this.checkYLocation();
      }
    );
    this.resizeEvent$ = fromEvent(window, 'resize').subscribe(
      (resizeEvent: any) => {
        this.updateIsMobile();
        this.checkYLocation();
      }
    );
  }

  checkYLocation() {
    let yRegionStart = this.el.nativeElement.getBoundingClientRect().top;
    yRegionStart -= 1; // offset an extra pixel so emitter fires when auto-scroll is used
    if (this.isMobileSize) { yRegionStart -= this.mobileOffset; }
    const yRegionEnd = yRegionStart + this.el.nativeElement.clientHeight;
    if (yRegionStart < 0 && yRegionEnd > 0) {
      this.isInScrollView.emit(this.el.nativeElement.id);
    }
  }

  updateIsMobile() {
    if (this.mobileScreenMaxSize && this.mobileOffset && window.innerWidth < this.mobileScreenMaxSize) {
      this.isMobileSize = true;
    } else {
      this.isMobileSize = false;
    }
  }

  ngOnDestroy() {
    this.scrollEvent$.unsubscribe();
  }

}
