import { Injectable } from '@angular/core';
declare var embedProgramOfInterest : any;
@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  setHeader() {
    var existingHeader = document.getElementById('program-of-interest-desktop');
    if (!existingHeader) {
        var header = {
        "emphasis_name": "",
        "emphasis_link": "",
        "emphasis_concentration": "",
        "program_name": "NAU Directory",
        "program_link": "#program-link",
        "program_concentration": ""
      };
      embedProgramOfInterest(header);
    } 
    
  }
}
