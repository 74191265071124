import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNgbDropdownScroll]'
})
export class NgbDropdownScrollDirective {

  constructor(private el: ElementRef) {
    el.nativeElement.addEventListener('keyup', this.scroll.bind(this));
  }

  scroll($event: any) {
    const elem = !!$event.currentTarget.nextElementSibling
      ? $event.currentTarget.nextElementSibling.getElementsByClassName('active')[0]
      : null;
    if (!!elem && !elem.attributes.disabled) {
      elem.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  }

}
