import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

import { Store } from '../../../../store';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface User {
  uid: string;
  authenticated: boolean;
  department?: any;
  isSupportStaff: boolean;
}

@Injectable()
export class AuthService {

  private _isUserAuthorizedSubject = new BehaviorSubject<boolean>(false);
  isUserAuthorized: Observable<boolean> = this._isUserAuthorizedSubject.asObservable();

  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(DOCUMENT) private document: Document,
    @Inject('authUserUrl') private authUserUrl: string,
    @Inject('loginOutUrl') private loginOutUrl: string
  ) { }

  setUser$ = this._http.get(this.authUserUrl).pipe(tap(next => {
    if (!next) {
      this._store.set('user', null);
      this._isUserAuthorizedSubject.next(false);
      return;
    }
    const user: User = {
      uid: next['uid'],
      authenticated: next['authenticated'],
      department: next['userDepartment'],
      isSupportStaff: next['isSupportStaff']
    };
    this._isUserAuthorizedSubject.next(next['Authorized']);
    this._store.set('user', user);
  }));

  login() {
    window.location.reload();
  }

  logout() {
    this.document.location.href = this.loginOutUrl;
  }

}
