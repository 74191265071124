import { Component, Input, OnInit, ViewEncapsulation, ViewChild, Inject, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { DynamicLayoutService } from '../../services/dynamic-layout.service';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-dynamic-layout',
  templateUrl: './dynamic-layout.component.html',
  styleUrls: ['./dynamic-layout.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DynamicLayoutComponent implements OnInit, AfterViewInit {

  @Input() sectionType = '';
  @ViewChild('htmlOutlet')
  sectionHtml: SafeHtml;
  isHeaderExpanded = false;

  constructor(private layout: DynamicLayoutService, @Inject(DOCUMENT) document) { }

  ngOnInit() {
    const test: SafeHtml = this.layout.$header;

    switch (this.sectionType) {
    case 'header':
      this.sectionHtml = this.layout.$header;
      break;
    case 'footer':
      this.sectionHtml = this.layout.$footer;
      break;
    case 'cta':
      this.sectionHtml = this.layout.$cta;
      break;
    case 'breadcrumbs':
        this.sectionHtml = this.layout.$breadcrumbs;
      break;
    }
  }

  ngAfterViewInit() {
    if (this.sectionType === 'header') {
      const menuItems = document.querySelectorAll('#mega-menu-department-menu > li > a');
      menuItems.forEach(element => {
        element.addEventListener('click', (event) => {
          event.preventDefault(); return false;
        } , false);
      });
    }
  }

}
