import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { SpaSettingsService } from '../../services/spa-settings.service';


@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.sass']
})
export class InfoCardComponent implements OnInit {

  @Input() contactInfo: any;
  @Input() departmentInfo: any;
  @Input() pushChanges: false;
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>();
  isCompact: false;
  private isImageLoading: boolean;

  constructor(private _contacts: ContactService, private cdr: ChangeDetectorRef, private _spaSettings: SpaSettingsService) { }

  ngOnInit() {
    if (this.contactInfo) {
      this._contacts.parseJobs(this.contactInfo);
      this.getImage();
    }
  }

  getImage() {
    if (this.contactInfo.image === undefined) {
      this.isImageLoading = true;
      this.contactInfo.image = new Image();
      this.contactInfo.image.onload = () => {
        updateDom();
      };
      this.contactInfo.image.onerror = () => {
        this.contactInfo.image = null;
        updateDom();
      };
      this.contactInfo.image.src = `${this._spaSettings.imageServerUrl}/${this.contactInfo.uid}/profile.jpg`;
    }

    const updateDom = () => {
      this.isImageLoading = false;
      if (this.pushChanges) { this.cdr.detectChanges(); }
    };
  }

  emitClick() {
    if (this.contactInfo) {
      this.cardClick.emit({ selectedValue: this.contactInfo });
    } else if (this.departmentInfo) {
      this.cardClick.emit({ selectedValue: this.departmentInfo });
    }
  }

  linkClick(event: any) {
    event.stopPropagation();
    return false;
  }

}
