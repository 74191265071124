import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap' ;
import { ErrorModalComponent } from '../components/error-modal/error-modal.component';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(protected modalService: NgbModal) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if ((error.status >= 500 || error.status === 404 || error.status === 400) && !request.url.startsWith('/api/image') ) {
          setTimeout(() => {
            this.modalService.open( ErrorModalComponent, { backdrop: 'static', keyboard: false });
          });
          return EMPTY;
        }
        return throwError(error);
      }));
  }

}
