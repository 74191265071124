import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subscription, interval } from 'rxjs';
import { map, tap, share } from 'rxjs/operators';
import { SearchComponent } from './../../shared/components/search/search.component';
import { ResultsTableComponent } from './../../shared/components/results-table/results-table.component';
import { ScrollService } from './../../shared/services/scroll.service';
import { ElasticService } from './../../shared/services/elastic.service';
import { SearchStateService } from './../../shared/services/search-state.service';
import { SpaSettingsService } from './../../shared/services/spa-settings.service';
import { HeaderService } from '../../shared/services/header.service';

@Component({
  selector: 'app-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.sass']
})
export class AllComponent implements OnInit, OnDestroy {

  @ViewChild(SearchComponent) search: SearchComponent;
  @ViewChild(ResultsTableComponent) resultsTable: ResultsTableComponent;
  selectedUser: any;
  isLoading: boolean;
  isSearchFocused: boolean;
  entrySearchText: string;
  routeParam = '';
  stateChange: Subscription;
  view = 'default';
  state: any;

  constructor(
    private _elastic: ElasticService,
    private _router: Router,
    public _searchState: SearchStateService,
    private _scrollService: ScrollService,
    private _headerService: HeaderService,
    private _settingsService: SpaSettingsService) {
      this.state = this._searchState.$all;
  }

  ngOnInit() {
    // set focus on search if not first time page load
    if (this._settingsService.isSettingsLoaded()) {
      this.isSearchFocused = true;
    }
    // URL handling
    this.stateChange = this._searchState.stateEvents.all.subscribe(
      newState => {
        this.routeParam = newState.searchFields.name ? newState.searchFields.name : '';
        if (!this.routeParam) {
          this.search.searchForm.get('nameSearch').setValue(null, { emitEvent: false });
        }
        this.setView();
      });
    if (this.state.searchFields && this.state.searchFields.name) {
      this.entrySearchText = this.state.searchFields.name;
    }
    this.setView();
    //this._headerService.setHeader();
  }

  ngOnDestroy() {
    this.stateChange.unsubscribe();
  }

  handleShowAll(value: any) {
    if (value.results.personHitsCount <= 10 && value.results.personHitsCount <= 10) {
      this.state.people = value.results.people;
      this.state.departments = value.results.departments;
      this.state.searchFields = { name: value.searchTerm };
    }
    this._searchState.setNavigation({ search: value.searchTerm });
  }

  handleSelectPerson(value: any, clearPreviousResults?: boolean) {
    this._scrollService.scrollTo();
    this.state.selectedValue = value.selectedValue;
    this.setView();
    if (clearPreviousResults) {
      this.state.people = [];
      this.state.departments = [];
      this.state.searchFields = { name: '' };
    }
    setTimeout(() => {
      this._searchState.setNavigation({ search: this.state.searchFields.name, person: value.selectedValue.uid });
    }, 500);
  }

  handleSelectDepartment(value: any, clearPreviousResults?: boolean) {
    this._router.navigate([`./departments`], { queryParams: { id: value.selectedValue.deptid } });
    if (clearPreviousResults) {
      this.state.people = [];
      this.state.departments = [];
    }
  }

  showPreviousResults() {
    this._searchState.setNavigation({ search: this.state.searchFields.name });
    this.state.selectedValue = null;
    this.setView();
  }

  getDirectoryData(term: string): Observable<any> {
    return this._elastic.searchDirectory(term, 1000).pipe(
      map((data: any) => this._elastic.mapElasticData(data.hits),
      error => of([]))
    );
  }

  getDepartmentData(term: string): Observable<any> {
    return this._elastic.searchDepartments(term, 1000).pipe(
      map(data => this._elastic.mapElasticData(data.hits),
      error => of([]))
    );
  }

  clearSearch() {
    this._searchState.setNavigation();
  }

  refresh() {
    this._searchState.setNavigation();
  }

  setView() {
    if (this.state.selectedValue) {
      this.view = 'person';
    } else if (this.state.people) {
      if (this.state.people.length <= 5) {
        this.view = 'list';
      } else {
        this.view = 'table';
      }
    } else {
      this.view = 'default';
    }
    this.isLoading = false;
  }

}
