<div class="wrapper">
  <app-nav [activeTab]="'all'" (refresh)="refresh()"></app-nav>
  <div class="outlet-page pb-5">
    <div class="container">
      <div class="row py-2">
        <div class="col-sm-8 offset-sm-2 my-4 my-sm-5">
          <nau-search
              #search
              [defaultSearchText]="entrySearchText"
              [isFocused]="isSearchFocused"
              (valueSelected)="handleSelectPerson($event, true)"
              (departmentSelected)="handleSelectDepartment($event)"
              (showAll)="handleShowAll($event)"
              (clearSearch)="clearSearch()"></nau-search>
        </div>
      </div>
      <div class="col-12 my-5 text-center" *ngIf="isLoading">
        <i class="fas fa-circle-notch fa-spin fa-4x text-nau-trueblue"></i>
      </div>
      <ng-container *ngIf="view !== 'default' && !isLoading">
        <div class="row">
          <ng-container *ngIf="routeParam && !state.people.length && !state.departments?.length">
            <div class="col-12 text-center">
              <h3>No results</h3>
            </div>
          </ng-container>
          <ng-container *ngIf="state.departments?.length && !state?.selectedValue">
            <div class="col-12 mb-5">
              <h4 class=" mb-3">
                {{state.departments?.length}} results for departments
              </h4>
              <app-info-card [departmentInfo]="result" *ngFor="let result of state.departments" (cardClick)="handleSelectDepartment($event)"></app-info-card>
            </div>
          </ng-container>
          <ng-container *ngIf="view == 'list'">
            <div class="col-12 mb-5">
              <h4 class=" mb-3" *ngIf="state.people?.length">
                {{state.people?.length}} results for people
              </h4>
              <app-info-card [id]="'card-' + result.uid" [contactInfo]="result" (cardClick)="handleSelectPerson($event)" *ngFor="let result of state.people" ></app-info-card>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <app-results-table
          [people]="state.people"
          [sorch]="state.sorch"
          (valueSelected)="handleSelectPerson($event)"
          *ngIf="view == 'table' && !isLoading"></app-results-table>
    </div>
    <ng-container *ngIf="view == 'person'">
      <div id="contact-result" class="contact-container" *ngIf="!!state.people?.length || !!state.departments?.length">
        <!-- <button class="btn btn-sm btn-nau-secondary my-3" (click)="showPreviousResults()">
          Back to Search Results
        </button> -->
        <a appKeyboardAnchor
          class="my-3"
          (click)="showPreviousResults()"
          >
          &lt; Back to search results
        </a>
      </div>

      <div class="row" *ngIf="showResults">
        <div class="col text-center text-md-left">
          <!-- <button class="btn btn-sm btn-nau-secondary px-5 py-2 my-1 mr-md-4 mb-3 mb-md-0" (click)="previousView()">
            Back to Search <span *ngIf="state.selectedValue">Results</span>
          </button> -->
            <a appKeyboardAnchor
              class="px-5 py-2 my-1 mr-md-4 mb-3 mb-md-0"
              (click)="previousView()"
              >
              &lt; Back to search <span *ngIf="state.selectedValue">results</span>
            </a>
          <ng-container *ngIf="!state.selectedValue">
            Search:
            <span *ngIf="state.searchFields.title"> {{ state.searchFields.title }}</span>
            <span *ngIf="state.searchFields.name"> {{ state.searchFields.name }}</span>
            <span *ngIf="state.searchFields.department"> in {{ state.searchFields.department }}</span>
            <span *ngIf="state.searchFields.keyword"> with {{ state.searchFields.keyword }}</span>
          </ng-container>
          <hr class="my-4" />
        </div>
      </div>
      <app-profile [contactId]="state.selectedValue.uid" [serviceName]="'all'" *ngIf="state.selectedValue"></app-profile>
    </ng-container>
  </div>
</div>
<app-scroll-to-top></app-scroll-to-top>
