
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { of, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Store } from './../../../store';

export interface MockData {
  userName: string,
  userDept: string,
  userTitle: string,
  userClassification: string
}

@Injectable()
export class MockDataService {
  /* 
    Remove after we have backend APIs set
  */
  mockData$: Observable<MockData[]> = this._http.get<MockData[]>('api/mockdata/GetMockData').pipe(
    tap(next => {
      this._store.set('mockDataResultSet', next);
    })
  );

  ldapData$: Observable<MockData[]> = this._http.get<MockData[]>('api/mockdata/GetMockData').pipe(
    tap(next => {
      this._store.set('ldapResultSet', next);
    })
  );

  psData$: Observable<MockData[]> = this._http.get<MockData[]>('api/mockdata/GetMockData').pipe(
    tap(next => {
      this._store.set('psResultSet', next);
    })
  );

  facultyData$: Observable<MockData[]> = this._http.get<MockData[]>('api/mockdata/GetMockData').pipe(
    tap(next => {
      this._store.set('facultyResultSet', next);
    })
  );


  constructor(
    private _http: HttpClient,
    private _store: Store
  ) { }

 

}