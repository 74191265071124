import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { pipe, of } from 'rxjs';

@Injectable()
export class PeopleSoftService {
  constructor(private _http: HttpClient) { }

  // PeopleSoft Campus Solution

  // Get
  getBuildings(): Observable<any> {
    return this._http.get(`/api/campussolution/GetBuildings`);
  }

  getStates(): Observable<any> {
    return this._http.get(`/api/campussolution/GetStates`);
  }

  // PeopleSoft HCM

  // Add
  addSupplementalDepartment(
    suppDeptDetail: any,
    deptId: string
  ): Observable<any> {
    return this._http.post(
      `/api/hcm/AddSupplementalDepartment/${deptId}`,
      suppDeptDetail
    );
  }

  // Get
  getDeptDetail(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetDeptDetail/${deptId}`);
  }

  getDeptTree() {
    return this._http.get(`/api/hcm/GetDepartmentTree`);
  }

  getDeptMaintainerMembership(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetDeptMaintainerMembership/${deptId}`);
  }

  getPersonMembership(emplId: string): Observable<any> {
    return this._http.get(
      `/api/hcm/GetPersonDepartments/${emplId}`
    );
  }

  getPersonMaintainerMembership(emplId?: string): Observable<any> {
    return this._http.get(
      `/api/hcm/GetPersonMaintainerMembership/${emplId ? emplId : ''}`
    );
  }

  getDeptMembership(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetDeptMembership/${deptId}`);
  }

  getDepartmentRollupMembers(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetDepartmentRollupMembers/${deptId}`);
  }

  getPartTimeFaculty(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetPartTimeFaculty/${deptId}`);
  }

  getDeptAdminOverride(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetDeptAdminOverride/${deptId}`);
  }

  getPersonOffice(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetPersonOffice/${deptId}`);
  }

  getSupplementalDepartment(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetSupplementalDepartment/${deptId}`);
  }

  getDepartmentTypes(): Observable<any> {
    return this._http.get(`/api/hcm/GetDepartmentTypes`);
  }

  getDepartmentJobTypes(): Observable<any> {
    return this._http.get(`/api/hcm/GetDepartmentJobTypes`);
  }

  getDegreesTaught(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetDegreesTaught/${deptId}`);
  }

  getPersonDegreesTaught(emplId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetPersonDegreesTaught/${emplId}`);
  }

  getDirectoryDegrees(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/GetDirectoryDegrees/${deptId}`);
  }

  getNauBuildings(): Observable<any>  {
    return this._http.get('/api/hcm/GetNauBuildings');
  }

  getUnpublishReasons(): Observable<any>  {
    return this._http.get('/api/hcm/GetUnpublishReasons');
  }

  getUnpublishedStaff(): Observable<any> {
    return this._http.get('/api/hcm/GetPersonPublish');
  }

  // Delete

  deleteDeptDetail(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/DeleteDepartmentDetails/${deptId}`);
  }

  deleteDeptAltMaintainerMembership(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/DeleteDepartmentAltMembership/${deptId}`);
  }

  deleteDeptMaintainerMembership(deptId: string): Observable<any> {
    return this._http.get(
      `/api/hcm/DeleteDepartmentMaintainerMembership/${deptId}`
    );
  }

  deleteSupplementalDepartment(deptId: string): Observable<any> {
    return this._http.get(`/api/hcm/DeleteSupplementalDepartment/${deptId}`);
  }

  deleteAltPersonDept(deptId: string, uid: string): Observable<any> {
    return this._http.get(`/api/hcm/DeleteAltPersonDept/${deptId}/${uid}`);
  }

  // Updates

  UpdateAltPersonDept(personDetail: any, deptId: string): Observable<any> {
    return this._http.post(
      `/api/hcm/UpdateAltPersonDept/${deptId}`,
      personDetail
    );
  }

  updatePersonOffice(office: any, emplId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this._http.post(
      `/api/hcm/UpdatePersonOffice/${emplId}`,
      office
    );
  }

  updateDepartmentDetails(details: any, deptId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this._http.post(
      `/api/hcm/UpdateDepartmentDetails/${deptId}`,
      details
    );
  }

  updateSupplementalDepartmentDetails(details: any, deptId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this._http.post(
      `/api/hcm/UpdateSupplementalDepartmentDetails/${deptId}`,
      details
    );
  }

  updateDepartmentAltMembership(
    memberDetails: any,
    deptId: string
  ): Observable<any> {
    return this._http.post(
      `/api/hcm/UpdateDepartmentAltMembership/${deptId}`,
      memberDetails
    );
  }

  updateDepartmentAdminOverride(
    memberDetails: any,
    deptId: string
  ): Observable<any> {
    return this._http.post(
      `/api/hcm/UpdateDepartmentAdminOverride/${deptId}`,
      memberDetails
    );
  }

  updateDepartmentMaintainerMembership(
    memberDetails: any,
    deptId: string
  ): Observable<any> {
    return this._http.post(
      `/api/hcm/UpdateDepartmentMaintainerMembership/${deptId}`,
      memberDetails
    );
  }

  addAlternatePersonDepartments(deptId: string, emplid: string, memberType): Observable<any> {
    return this._http.post(
      `/api/hcm/AddAltPersonDept/${deptId}/${emplid}/${memberType}`,
      ''
    );
  }

  updatAlternatePersonDepartments(deptId: string, emplId: string, memberType): Observable<any> {
    return this._http.post(
      `/api/hcm/UpdateAltPersonDept/${deptId}/${emplId}/${memberType}`,
      ''
    );
  }

  approveDegreeTaught(deptId: string, emplId: string, acadPlan: string) {
    return this._http.post(
      `/api/hcm/ApproveDegreeTaught/${deptId}/${emplId}/${acadPlan}`,
      ''
    );
  }

  declineDegreeTaught(deptId: string, emplId: string, acadPlan: string) {
    return this._http.post(
      `/api/hcm/DeclineDegreeTaught/${deptId}/${emplId}/${acadPlan}`,
      ''
    );
  }

  removeDegreeTaught(deptId: string, emplId: string, acadPlan: string) {
    return this._http.post(
      `/api/hcm/RemoveDegreeTaught/${deptId}/${emplId}/${acadPlan}`,
      ''
    );
  }

  unpublish(uid: string, reason: string) {
    return this._http.get(`/api/hcm/Unpublish/${uid}/${reason}`);
  }

  publish(uid: string, reason: string) {
    return this._http.get(`/api/hcm/Publish/${uid}/${reason}`);
  }

}
