import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telephoneFormat'
})
export class TelephoneFormatPipe implements PipeTransform {

  transform(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.replace(/^\+1 /g, '') : '';
  }

}
