import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap' ;
import { AuthModalComponent } from '../../components/auth-modal/auth-modal.component';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CasInterceptor implements HttpInterceptor {

  constructor(protected modalService: NgbModal, private xsrfTokenExtractor: HttpXsrfTokenExtractor) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xsrfToken = this.xsrfTokenExtractor.getToken();
    let authorizedRequest;
    if ((request.url.startsWith('/api/hcm/') || request.url.startsWith('/api/elastic/adminLookup')) && xsrfToken) {
      authorizedRequest = request.clone({
        withCredentials: true,
        headers: request.headers.set('X-XSRF-TOKEN', xsrfToken)
      });
    } else {
      authorizedRequest = request;
    }
    return next.handle(authorizedRequest).pipe(
      catchError(error => {
        if (error.status === 401) {
          setTimeout(() => {
            this.modalService.open( AuthModalComponent, { backdrop: 'static', keyboard: false });
          });
          return EMPTY;
        }
        return throwError(error);
      }));
  }

}
