import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  switchMap,
} from 'rxjs/operators';
import { ElasticService } from './../../services/elastic.service';
import { ContactService } from './../../services/contact.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'nau-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit, AfterViewInit {

  @Input() filter = 'none';
  @Input() searchType = 'all';
  @Input() initSearch = '';
  @Input() isAdmin = false;
  @Input() isCompact = false;
  @Input() defaultSearchText: string;
  @Input() isFocused = false;
  @Output() showAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchResults: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() departmentSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('searchInput', { static: false }) public searchElem: ElementRef;

  search: any;
  searchSource: Observable<any>;
  isLoading = false;
  departmentData: Array<any>;
  directoryData: Array<any>;
  dropDownData: Array<any>;
  departmentDropDownData: Array<any>;
  inlcudeAll = true;
  searchForm: UntypedFormGroup;
  personHitsCount: number;
  departmentHitsCount: number;
  isSearchInProgress: boolean;
  searchTerm: string;
  searchHasFocus: boolean;
  searching: Observable<any>;
  personSearch: Subscription;
  departmentSearch: Subscription;
  stop: boolean;


  searchFormatter = (x: any) => {
      if (x.isInitVal) {
        return this.defaultSearchText;
      } else if (x.isManualEntry) {
        return x.value;
      } else if (x.uid) {
        return `${x.givenName} ${x.sn}`;
      } else  {
        return  x.n__dept_longdescr;
      }
    }

  constructor(
    private _elastic: ElasticService,
    private _formBuilder: UntypedFormBuilder,
    private _contactService: ContactService
  ) {
    this.search = (text$: Observable<string>) =>
      text$.pipe(
        // debounceTime and distinctUntilChanged after map
        // per https://medium.com/@kavisha.talsania/rxjs-debouncetime-and-distinctuntilchanged-c9f11db4d45f
        switchMap(term => {
          const trimmedTerm = this.parseSearch(term);
          this.searchTerm = trimmedTerm;
          if (trimmedTerm.length > 2) {
            this.isLoading = true;
            this.isSearchInProgress = true;
            return this.handleSearch(trimmedTerm);
          } else {
            return of([]);
          }
        }),
        debounceTime(500),
        distinctUntilChanged()
      );
  }

  ngAfterViewInit() {
    if (!this.isCompact && this.isFocused) {
      this.searchElem.nativeElement.focus(
        {
          preventScroll: true
        }
      );
    }
  }

  ngOnInit() {
    this.createForm();
    if (this.searchType !== 'all') {
      this.inlcudeAll = false;
    }
    if (!!this.defaultSearchText) {
      const searchObj = { isInitVal: true, searchVal: this.defaultSearchText };
      this.searchForm.get('nameSearch').setValue(searchObj, { emitEvent: false });
    }
  }

  createForm() {
    this.searchForm = this._formBuilder.group({
      nameSearch: [''],
      dropDown: ['']
    });
  }

  handleSearch(term: string): Observable<any> {
    this.isSearchInProgress = true;
    this.personHitsCount = 0;
    this.departmentHitsCount = 0;
    let personSearch: Observable<any>;
    let departmentSearch: Observable<any>;
    switch (this.searchType) {
    case 'all' :
      personSearch = this.getDirectoryData(term);
      departmentSearch = this.getDepartmentData(term);
      break;
    case 'person' :
      personSearch = this.getDirectoryData(term);
      departmentSearch = of ([]);
      break;
    case 'department' :
      personSearch = personSearch = of([]);
      departmentSearch = this.getDepartmentData(term);
      break;
    }

    return forkJoin({
      people: personSearch.pipe(
        map(data => {
          this.directoryData = data;
          return data;
        })
      ),
      departments: departmentSearch.pipe(
        map(data => {
          this.departmentData = data;
          return data;
        })
      ),
    }).pipe(
      tap(allResults => { this.isLoading = false; }),
      map(allResults => {
        this.dropDownData = allResults.people || [];
        this.departmentDropDownData = allResults.departments || [];
        if (allResults.departments.length && allResults.people.length) {
          allResults.departments.push({ isSeparator: true });
        }
        const mergedResults = allResults.departments.concat(allResults.people);
        if (!mergedResults.length) {
          mergedResults.push({ isManualEntry: true, value: term, resultCount: 0 });
        } else if (!this.isAdmin && !!term && mergedResults.length > 1) {
              mergedResults.unshift({ isManualEntry: true, value: term, resultCount: this.personHitsCount + this.departmentHitsCount });
          if (this.inlcudeAll && this.personHitsCount > 10) {
            mergedResults.push({ isManualEntry: true, value: `... View ${this.personHitsCount + this.departmentHitsCount} results` });
          }
        }
        return mergedResults;
      })
    );
  }

  getDirectoryData(term: string): Observable<any> {
    if (this.isAdmin) {
      return this._elastic.adminSearchDirectory(term).pipe(
        map(results => {
          this.personHitsCount += results.total.value;
          const processedHits = this._elastic.mapElasticData(results.hits).map(
            person => {
              return this._contactService.parseJobs(person);
            }
          );
          return processedHits;
        },
        error => {
          return of([]);
        })
      );
    } else {
      return this._elastic.searchDirectory(term).pipe(
        map(results => {
          this.personHitsCount += results.total.value;
          const processedHits = this._elastic.mapElasticData(results.hits).map(
            person => {
              return this._contactService.parseJobs(person);
            }
          );
          return processedHits;
        },
        error => {
          return of([]);
        })
      );
    }
  }

  getDepartmentData(term: string): Observable<any> {
    return this._elastic.searchDepartments(term).pipe(
      map(results => {
        this.departmentHitsCount += results.total.value;
        return this._elastic.mapElasticData(results.hits);
      },
      error => {
        return of([]);
      })
    );
  }

  selectedItem(event: any) {
    if (event.item.isManualEntry) {
      this.handleShowAll();
    } else if (!!event.item.uid) {
      this.handleSelected(event.item);
    } else {
      this.handleSelectedDepartment(event.item);
    }
  }

  handleSelected(value: any) {
    this.valueSelected.emit({
      searchTerm: this.searchForm.get('nameSearch').value,
      selectedValue: value
    });
    this.clearResultsDropdown();
  }

  handleSelectedDepartment(value: any) {
    this.departmentSelected.emit({
      searchTerm: this.searchForm.get('nameSearch').value,
      selectedValue: value
    });
    this.clearResultsDropdown();
  }

  handleShowAll() {
    const searchTerm = this.parseSearch(this.searchForm.get('nameSearch').value);
    if (this.searchTerm === searchTerm) {
      this.showAll.emit({
          searchTerm: searchTerm,
          results: {
            people: this.dropDownData,
            departments: this.departmentDropDownData.filter(dept => !dept.isSeparator),
            personHitsCount: this.personHitsCount,
            departmentHitsCount: this.departmentHitsCount
          }
        });
      this.clearResultsDropdown();
    }
  }

  listSize(): any {
    const departmentCount = this.departmentDropDownData.length;
    const peopleCount = this.dropDownData.length;
    const countToDisplay = { people: 5, departments: 5 };
    if (departmentCount < 5) {
      countToDisplay.people = 10 - departmentCount;
    }
    if (peopleCount < 5) {
      countToDisplay.departments = 10 - peopleCount;
    }
    return countToDisplay;
  }

  clearResultsDropdown(isClearSearchField?: boolean) {
    this.departmentDropDownData = [];
    this.dropDownData = [];
    this.isSearchInProgress = false;
    if (isClearSearchField) {
     this.searchForm.get('nameSearch').setValue(null, { emitEvent: false });
     this.clearSearch.emit(isClearSearchField);
    }
  }

  parseSearch(inText: string) {
    const searchFormat = /[^a-zA-Z.*,'\-\" ]/g;
    let outText = inText.trim().replace(searchFormat, '').trim();
    if ((outText.match(/"/g) || []).length % 2 === 1) {
      const lastQuote = outText.lastIndexOf('\"');
      outText = outText.slice(0, lastQuote) + outText.slice(lastQuote + 1);
    }
    return outText;
  }

}
