import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.sass']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() height: any;
  @Input() style = 'fa-2x';
  @Input() absolute = false;

  constructor() { }

  ngOnInit() { }

}
