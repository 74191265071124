import { Component, ElementRef, OnInit, ViewChild, Input, Output,  } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { of, Subject, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ElasticService } from '../../shared/services/elastic.service';
import { ContactService } from '../../shared/services/contact.service';
import { SearchStateService } from '../../shared/services/search-state.service';
import { SpaSettingsService } from '../../shared/services/spa-settings.service';
import { HeaderService } from '../../shared/services/header.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {

  @Input() serviceName;
  @ViewChild('profileLink') profileLink: ElementRef;
  @ViewChild(NgbTooltip) profileTip: NgbTooltip;
  _contact: any;
  _contactId: string;
  contactImage: any;
  contactImageUrl: any;
  scrollViewIndicator: string;
  isContactLoaded = false;
  isContactValid = true;
  isHiddenCoursesCollapsed = true;
  isImageAvailable = true;
  isImageLoading: boolean;
  mobileScreenMaxSize = 992;
  mobileNavHeight = 150;
  offset = 65;
  month_seasonSortKey = ['jan', 'feb', 'spring', 'march', 'april', 'may',
  'jun', 'jul', 'summer', 'aug', 'sept', 'oct', 'autumn', 'fall', 'nov', 'dec', 'winter'];
  copyLinkTip = 'Copy link to this profile';
  isStandAlone: boolean;
  activeTab: string;

  constructor(
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private elastic: ElasticService,
    private _contactService: ContactService,
    private _searchState: SearchStateService,
    private _spaSettings: SpaSettingsService,
    private _headerService: HeaderService) { }
    

  @Input()
  set contactId(value: string) {
    if (!!this._contactId) {
      this.isContactLoaded = false;
      this.initContact(value);
    }
    this._contactId = value;
  }

  get contactId(): string {
    return this._contactId;
  }

  ngOnInit() {
    if (this._contactId) {
      if (this.serviceName === 'advising' || !this._searchState['$' + this.serviceName].selectedValue.isLoaded) {
        this.initContact(this._contactId);
      } else {
        this._contact = this._searchState['$' + this.serviceName].selectedValue;
        this.isContactLoaded = true;
      }
    } else if (this.route.snapshot.paramMap.get('id')) {
      this.initContact(this.route.snapshot.paramMap.get('id'));
      this.isStandAlone = true;
    }
    //this._headerService.setHeader();
  }

  initContact(contactId: string): any {
    this.elastic.searchContact(contactId).subscribe(
      contactResponse => {
        this.isContactLoaded = true;
        if (!contactResponse) {
          this.isContactValid = false;
          return;
        }
        // select navigation tab baseed on employee affiliation
        if (contactResponse.edupersonprimaryaffiliation !== 'staff' && contactResponse.edupersonprimaryaffiliation !== 'faculty') {
          this.activeTab = 'all';
        } else {
          this.activeTab = contactResponse.edupersonprimaryaffiliation;
        }
        this.isImageLoading = true;
        contactResponse.image = new Image();
        contactResponse.image.onload = () => {
          this.isImageLoading = false;
          this.isImageAvailable = true;
        };
        contactResponse.image.onerror = () => {
          contactResponse.image = null;
          this.isImageLoading = false;
          this.isImageAvailable = false;
        };
        contactResponse.image.src = `${this._spaSettings.imageServerUrl}/${contactId}/profile.jpg`;
        contactResponse.isLoaded = true;
        this._contact = this.prepForTemplate(contactResponse);
        if (this.serviceName) {
          this._searchState['$' + this.serviceName].selectedValue = this._contact;
        }
      }
    );
  }

  prepForTemplate(contact: any): any {
    contact.naueduworkphone = contact.naueduworkphone ? this.formatPhone(contact.naueduworkphone) : '';
    this._contactService.parseJobs(contact);
    contact.secondaryJobs = [];
    for (const job of contact.departments) {
      if (!contact.jobs.find(existingJob => existingJob.deptId === job.deptid)) {
        contact.secondaryJobs.push(job);
      }
    }
    this.setDepartmentInfo(contact);
    contact.infoSections = {};
    this.mapActivitiesToViewSections(contact);
    this.setProgramsSection(contact);
    this.setCoursesSection(contact);
    this.setAdvisingSection(contact);
    if (!!contact.bio) {
      contact.infoSections.biography = contact.bio;
    }
    contact.hasInfo = Object.keys(contact.infoSections).length > 0;
    return contact;
  }

  formatPhone(phone: string) {
    const strippedPhone = phone.replace(/\D|/g, '');
    return `+${strippedPhone.slice(0, 1)} ${strippedPhone.slice(1, 4)}-${strippedPhone.slice(4, 7)}-${strippedPhone.slice(7)}`;
  }

  setDepartmentInfo(contact: any) {
    const primaryDepartment = !contact.departmentNumber
      ? contact.departments[0] || null
      : contact.departments.find(department => department.deptid === contact.departmentNumber);
    if (!!primaryDepartment) {
      contact.descr = primaryDepartment.descr || '';
      contact.building = contact.building || primaryDepartment.building;
      contact.room = contact.room || primaryDepartment.room;
    }
    if (!!contact.affiliations) {
      const matchedAffiliations = [];
      contact.affiliations.forEach(
        affiliation => {
          const dept = contact.departments.find(department => department.deptid === affiliation.deptid);
          if (dept) {
            matchedAffiliations.push({ title: affiliation.title, department: dept.descr });
          }
        }
      );
      if (matchedAffiliations.length) {
        contact.affiliations = matchedAffiliations;
      }
    }
  }

  resetProfileLinkTip() {
    this.copyLinkTip = 'Copy link to this profile';
  }

  copyProfileLink() {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = 'https://' + window.location.hostname;
    el.value += window.location.port ? ':' + window.location.port : '';
    el.value += '/person/' + this._contact.uid;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    Object.assign(el, { position: 'absolute', left: '-9999px' });
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
    this.profileLink.nativeElement.focus();
    this.copyLinkTip = 'Link copied';
  }

  mapActivitiesToViewSections(contact: any) {
    if (!contact.activities) { return; }
    Object.keys(contact.activities).forEach(activity => {
      if (contact.activities[activity].length) {
        switch (activity) {
        case 'biography' :
          const bioContent = this.htmlFix(contact.activities.biography[0].biography);
          contact.infoSections.biography =  this._sanitizer.bypassSecurityTrustHtml(bioContent);
          break;
        case 'degrees' :
          contact.infoSections.degrees = contact.activities.degrees.filter( degree => !!degree.year_conferred );
          contact.infoSections.degrees = this.degreeSort(contact.infoSections.degrees);
          contact.infoSections.degrees = contact.infoSections.degrees.map(degree => {
            return { title : this.htmlFix(degree.degree + ' ' + degree.discipline),
              granting_institution: this.htmlFix(degree.granting_institution) };
          });
          break;
        case 'experience' :
          contact.infoSections.experience = this.experienceSort(contact.activities.experience);
          contact.infoSections.experience.forEach(experienceInfo => {
            experienceInfo.organization = this.htmlFix(experienceInfo.organization);
          });
          break;
        case 'productions' :
          this.formatProductions(contact.activities.productions);
          contact.infoSections.scholarship = this.productionSort(contact.activities.productions);
          break;
        case 'interests' :
          const researchInterests = [];
          const teachingInterests = [];
          contact.activities.interests.forEach(interest => {
            if (!!interest.research) {
              researchInterests.push(this.htmlFix(interest.research));
            }
            if (!!interest.teaching) {
              teachingInterests.push(this.htmlFix(interest.teaching));
            }
          });
          contact.infoSections.interests = researchInterests.length || teachingInterests.length
            ? { research: researchInterests, teaching: teachingInterests }
            : null;
          break;
        case 'grants' :
          contact.activities.grants.forEach(grant => {
            grant.status.sort((a, b) => b.completionorder - a.completionorder); // probably unnecessary after changes on API side
            const coAuthorsString = !grant.coauthors ? '' : grant.coauthors.reduce((accumulator, currentValue, index) => {
              const article = index === 1 ? ' with' : index > 1 ? ',' : '';
              const stringOut = `${article} ${currentValue.authortype ? currentValue.authortype : ''}`
              + ` ${currentValue.firstname} ${currentValue.lastname}`;
              return accumulator + stringOut;
              }, '');
            const endDate = grant.end_date ? ' - ' + dayjs(grant.end_date).format('MMMM D, YYYY') : '';
            const totalFunding =  grant.total_funding ? ` ($${Number(grant.total_funding).toLocaleString('en')}.00), ` : '';
            const status = grant.status[0].statuslabel ? `${grant.status[0].statuslabel}, ` : '';
            const term = grant.status[0].term ? ` ${grant.status[0].term}` : '';
            const year = grant.status[0].year ? ` ${grant.status[0].year},` : '';
            grant.citation = `${grant.title}, Funded by ${grant.sponsor}`
              + ` (${dayjs(grant.start_date).format('MMMM D, YYYY')}${endDate})${totalFunding}${status}${term}${year}${coAuthorsString}`;
            grant.citation = grant.citation.replace(/ *,/g, ',');
            grant.citation = this.htmlFix(grant.citation);
          });
          contact.infoSections.grants = contact.activities.grants.length ? contact.activities.grants : null;
          break;
        case 'certifications' :
          contact.infoSections.certifications = this.certificationSort(contact.activities.certifications);
          break;
        case 'honors' :
          contact.infoSections.honors = this.honorsSort(contact.activities.honors);
          break;
        case 'membership' :
          contact.infoSections.membership = this.membershipSort(contact.activities.membership);
          contact.infoSections.membership = contact.infoSections.membership.map(
            membership => {
              membership.yearLabel = membership.year_started.toString();
              membership.yearLabel += !membership.year_ended ? ' - Present' : ' - ' + membership.year_ended;
              return membership;
            });
          break;
        }
      }
    });
    // clean up unused object properties
    delete contact['activities'];
  }

  setProgramsSection(contact: any) {
    if (!contact.programstaught || !contact.programstaught.length) { return; }
    contact.infoSections.programs = contact.programstaught;
    // clean up unused object properties
    delete contact['programstaught'];
  }

  setCoursesSection(contact: any) {
    if (!contact.courses || !contact.courses.length) { return; }
    contact.courses = contact.courses.reverse();
    const groupedCourses = [];
    contact.courses.forEach((course, index) => {
      // group by catalog number and course title
      course.groupKey = course.catalog_nbr + course.course_title;
      // is first course in array or term is different than previous course
      if (index === 0 || course.n__term_descr !== contact.courses[index - 1].n__term_descr) {
        groupedCourses.push({ n__term_descr: course.n__term_descr, courses: [course] });
      } else {
        const currentGroup = groupedCourses[groupedCourses.length - 1].courses;
        if (!currentGroup.some(previousCourse => course.groupKey === previousCourse.groupKey)) {
          currentGroup.push(course);
        }
      }
    });
    groupedCourses.forEach(courseGroup => this.courseSort(courseGroup.courses));
    // hide extra course groups if there are more than 8
    if (groupedCourses.length) {
      if (groupedCourses.length > 8) {
        contact.infoSections.hiddenCourseGroups = groupedCourses.splice(4, groupedCourses.length);
        contact.infoSections.courses = groupedCourses.splice(0, 4);
      } else {
        contact.infoSections.courses = groupedCourses;
      }
    }
    // clean up unused object properties
    delete contact['courses'];
  }

  setAdvisingSection(contact: any): boolean {
    if (!contact.advisingspecialties && !contact.advisingplans) { return false; }
    if (contact.advisingspecialties && contact.advisingspecialties.length) {
      contact.infoSections.advisingSpecialties = contact.advisingspecialties;
    }
    if (contact.advisingplans && contact.advisingplans.length) {
      contact.infoSections.advisingDegrees = contact.advisingplans;
    }
    return !!contact.infoSections.advisingDegrees || !!contact.infoSections.advisingSpecialties;
  }

  formatProductions(productionData: any[]) {
    for (const production of productionData) {
      production.citation = coAuthorString(production.coauthors);
      const year = production.year ? ` (${production.year})` : '';
      const location = production.location ? ` ${production.location}:` : '';
      const monthSeason = production.month_season ? `, ${production.month_season}` : '';
      switch (production.type) {
      case 'Journal Publication':
        production.citation += ` ${year}. ${production.title}. `
          + `<span class="font-italic">${production.journal}, `
          + `${production.volume}${production.number ? `(${production.number}),` : ''}</span> ${production.pages}`;
        break;
      case 'Book':
        production.citation += `${year}. <span class="font-italic">`
          + `${production.title}</span> (p. ${production.pages}).${location} ${production.publisher}`;
        break;
      case 'Chapter':
        production.title = production.book_title;
        production.citation += `${year}. ${production.chapter_title}. <span class="font-italic">`
        + `${production.book_title}</span> (p. ${production.pages}).${location} ${production.publisher} `;
        break;
      case 'Case Study':
        production.citation += ` ${production.title}. ${production.case_source_publisher} ${production.pages} ${year}. `;
        break;
      case 'Creative Performance':
        production.citation += `${year}. <span class="font-italic">`
          + `${production.title}. ${production.event}</span>${location} ${production.sponsoring_organization}`;
        break;
      case 'Creative Production':
        production.citation += `${year} <span class="font-italic">${production.title}. ${production.outlet_venue}`
         + `</span>${location} ${production.sponsoring_organization}`;
        break;
      case 'Other':
        production.citation += `${year}. ${production.title} <span class="font-italic">${production.outlet}</span>`;
        break;
      case 'Other Teaching Materials':
        production.citation += `${year}. <span class="font-italic">${production.title} ${production.outlet}</span>`;
        break;
      case 'Patent':
        production.citation += `. ${production.title}. ${production.patent_number}`;
        break;
      case 'Presentation': // same format as poster presentation
      case 'Poster Presentation':
        production.citation += ` (${production.year}${monthSeason}). <span class="font-italic">`
          + `${production.title} ${production.conference_event}</span>.${location} ${production.sponsoring_organization}`;
        break;
      case 'Proceedings Publication':
        let productionInfo = `${production.volume ? 'In Vol. ' + production.volume : ''}`
        + `${production.pages ? ', pp.' + production.pages : ''}`;
        productionInfo = productionInfo ? `(${productionInfo}).` : '';
        production.citation += ` (${production.year}${monthSeason}).${production.title} <span class="font-italic">`
          + `${production.conference}.</span>${productionInfo}${location} ${production.publisher}`;
        break;
      case 'Review':
        production.citation += `${year}. ${production.title} ${production.location}: ${production.publisher}`;
        break;
      }
      production.citation = production.citation.normalize();
      // clean up text artifacts from missing fields
      production.citation = production.citation
        .replace(/ *<\/span>/g, '</span>')
        .replace(/<span class="font-italic"><\/span>/g, '')
        .replace(/(\. +\.)|(\.\.)/g, '.')
        .replace(/\s*,/g, ',')
        .replace(/(\: *$)|( *$)|(, *$)/, '')
        .replace(/ *,<\/span>$/, '</span>');
      // add missing period to end of string
      if (!production.citation.endsWith('.') && !production.citation.endsWith('.</span>')) {
        production.citation += '.';
      }
    }

    function coAuthorString(coAuthors: any[]): string {
      let coAuthorsOut = '';
      if (!!coAuthors) {
        coAuthors.forEach((coAuthor, index) => {
          coAuthorsOut += `${index > 0 ? index === coAuthors.length - 1 ? ', &amp; ' : ' ' : ''}`
          + `${coAuthor.lastname}, ${coAuthor.firstname.charAt(0)}. ${coAuthor.middleinitial.charAt(0)}.`;
        });
      }
      return coAuthorsOut;
    }
  }

  htmlFix(html: string) { // convert html codes from faculty 180
    return html.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"');
  }

  courseSort(corseData: any[]) {
    return corseData.sort((a, b) => {
      if (a.subject !== b.subject) {
        if (a.subject > b.subject) {
          return 1;
        }
        if (a.subject < b.subject ) {
          return -1;
        }
      }
      return Number(a.catalog_nbr.substring(0, 3)) - Number(b.catalog_nbr.substring(0, 3));
    });
  }

  degreeSort(degreeData: any[]) {
    return degreeData.sort((a, b) => {
      return a.year_conferred - b.year_conferred;
    });
  }

  experienceSort(experienceData: any[]) {
    return experienceData.sort((a, b) => {
      if (b.end_year === 'Present') {
        return 1;
      }
      return b.start_year - a.start_year;
    });
  }

  productionSort(productionData: any[]) {
    const groupedProductions = [];
    for (let i = 0; i < productionData.length; i++) {
      const production = productionData[i];
      production.isCollapsed = true;
      production.sortMonthSeason = !!production.month ? production.month : production.month_season;
      if (!!production.sortMonthSeason) {
        const sortMonthSeason = production.sortMonthSeason.toLowerCase();
        for (let s = 0; s < this.month_seasonSortKey.length; s++) {
          if (sortMonthSeason.indexOf(this.month_seasonSortKey[s]) !== -1) {
            production.monthRank = s;
            break;
          }
        }
      } else {
        production.monthRank = this.month_seasonSortKey.length;
      }
      let groupfound = false;
      for (let s = 0; s < groupedProductions.length; s++) {
          if (groupedProductions[s].name === production.type) {
            groupfound = true;
            groupedProductions[s].productions.push(production);
            break;
          }
      }
      if (!groupfound) {
        groupedProductions.push({ name: production.type, productions: [production] });
      }
    }
    for (let i = 0; i < groupedProductions.length; i++) {
      groupedProductions[i].productions = groupedProductions[i].productions.sort((a, b) => {
        if (a.year < b.year) {
          return 1;
        }
        if (a.year > b.year) {
          return -1;
        }
        if (a.year === b.year) {
          if (a.monthRank < b.monthRank) {
            return -1;
          }
          if (a.monthRank > b.monthRank) {
             return 1;
          }
          return 0;
        }
      });
    }
    groupedProductions.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return groupedProductions;
  }

  certificationSort(certificationData: any[]) {
    return certificationData.sort((a, b) => {
      if (a.year_conferred === b.year_conferred) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
           return 1;
        }
        return 0;
      }
      return b.year_conferred - a.year_conferred;
    });
  }

  honorsSort(honorsData: any[]) {
    return honorsData.sort((a, b) => {
      if (a.year_conferred === b.year_conferred) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
           return 1;
        }
        return 0;
      }
      return b.year_conferred - a.year_conferred;
    });
  }

  membershipSort(membershipData: any[]) {
    return membershipData.sort((a, b) => {
      if (!a.year_ended) {
        return 1;
      }
      if (a.year_ended === b.yearEnded) {
        if (a.year_started === b.year_started) {
          if (a.organization_name < b.organization_name) {
            return -1;
          }
          if (a.organization_name > b.organization_name) {
             return 1;
          }
          return 0;
        }
        return a.year_started - b.year_started;
      }
      return a.year_conferred - b.year_conferred;
    });
  }

}
