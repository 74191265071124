import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class ElasticService {
  constructor(private _http: HttpClient) { }

  searchDirectory(query: string, size?: number): Observable<any> {
    return this._http.get(`/api/elastic/directory?s=${query}&size=${size || '10'}`);
  }

  adminSearchDirectory(searchTerm: string): Observable<any>  {
    return this._http.get(`/api/elastic/adminLookup?s=${searchTerm}`);
  }

  searchStaff(query: any, size?: number): Observable<any> {
    let queryString =
      `${query.department && !query.deptid ? '&department=' + query.department : '' }`
      + `${query.name ? '&name=' + query.name : '' }`
      + `${query.title ? '&title=' + query.title : '' }`
      + `${query.deptid ? '&deptid=' + query.deptid : '' }`;
    queryString = '?' + queryString.substr(1, queryString.length);
    return this._http.get(`/api/elastic/staff${queryString}&size=${size || '10'}`);
  }

  searchFaculty(query: any, size?: number): Observable<any> {
    let queryString =
      `${query.department && !query.deptid ? '&department=' + query.department : '' }`
      + `${query.keyword ? '&keyword=' + query.keyword : '' }`
      + `${query.name ? '&name=' + query.name : '' }`
      + `${query.title ? '&title=' + query.title : '' }`
      + `${query.deptid ? '&deptid=' + query.deptid : '' }`;
    queryString = '?' + queryString.substr(1, queryString.length);
    return this._http.get(`/api/elastic/faculty${queryString}&size=${size || '10'}`);
  }

  searchAll(searchTerm: string): Observable<any>  {
    return forkJoin({
      people: this.searchDirectory(searchTerm, 1000).pipe(
        map(data => this.mapElasticData(data.hits))
      ),
      departments: this.searchDepartments(searchTerm, 1000).pipe(
        map(data => this.mapElasticData(data.hits))
      ),
    });
  }

  searchDepartments(query: string, size?: number): Observable<any> {
    return this._http.get(`/api/elastic/departments?s=${query}&size=${size || '10'}`);
  }

  getAllDepartments(): Observable<any> {
    return this._http.get(`/api/elastic/departments?size=1000`);
  }

  getDepartment(deptId: string): Observable<any> {
    return this._http.get(`/api/elastic/department?id=${deptId}`);
  }

  getDepartmentPeople(deptId: string): Observable<any> {
    return this._http.get(`/api/elastic/departmentpeople?id=${deptId}`);
  }

  getDepartmentTree(): Observable<any> {
    return this._http.get(`/api/elastic/departmenttree`);
  }

  getDepartmentSubTree(deptId: string): Observable<any> {
    return this._http.get(`/api/elastic/departmentsubtree?id=${deptId}`);
  }

  searchCombined(query: string, tab: string): Observable<any> {
    return this._http.get(`/api/elastic/combined?q=${query}&t=${tab}`);
  }

  searchContact(query: string): Observable<any> {
    return this._http.get(`/api/elastic/directory/${query}`);
  }

  searchAllAdvisors(excludeGateway?: boolean) {
    const queryString = excludeGateway ? 'excludeGateway=true' : '';
    return this._http.get(`/api/elastic/AdvisorSearch` + queryString).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisorsByCampus(campus: string, excludeGateway?: boolean) {
    if (!campus) {
      return;
    }
    let queryString = `?campus=${campus}`;
    queryString += '&excludeGateway=true';
    return this._http.get(`/api/elastic/AdvisorSearch` + queryString).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisorsByMode(mode: string, excludeGateway?: boolean) {
    if (!mode) {
      return;
    }
    let queryString = `?mode=${mode}`;
    queryString += '&excludeGateway=true';
    return this._http.get(`/api/elastic/AdvisorSearch` + queryString).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisorsByPlan(plan: string, career: string, queryParams?: { campus?: string, mode?: string }, excludeGateway?: boolean) {
    let queryString = `&career=${career}`;
    if (queryParams) {
      queryString += queryParams.campus ? `&campus=${queryParams.campus}` : '';
      queryString += queryParams.mode ? `&mode=${queryParams.mode}` : '';
    }
    queryString += excludeGateway ? `?excludeGateway=true` : '';
    return this._http.get(`/api/elastic/AdvisorSearch/?plan=${plan}${queryString}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisorsByOrg(
      org: string,
      queryParams?: { career?: string, campus?: string, mode?: string, org?: string },
      excludeGateway?: boolean) {
    let queryString = '';
    if (queryParams) {
      queryString += queryParams.career ? `&career=${queryParams.career}` : '';
      queryString += queryParams.campus ? `&campus=${queryParams.campus}` : '';
      queryString += queryParams.mode ? `&mode=${queryParams.mode}` : '';
    }
    queryString += excludeGateway ? `?excludeGateway=true` : '';
    return this._http.get(`/api/elastic/AdvisorSearch/?org=${org}${queryString}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  getAdvisors(params) {
    if (params) {
      Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    }
    const queryParamsString = '?' + new HttpParams({ fromObject: params }).toString();
    return this._http.get(`/api/elastic/AdvisorSearch${queryParamsString}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  getAdvisingPlans(params) {
    if (params) {
      Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    }
    const queryParamsString = '?' + new HttpParams({ fromObject: params }).toString();
    return this._http.get(`/api/elastic/AdvisingPlanSearch${queryParamsString}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  getAdvisingModes(params) {
    if (params) {
      Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    }
    const queryParamsString = '?' + new HttpParams({ fromObject: params }).toString();
    return this._http.get(`/api/elastic/AdvisingModeSearch${queryParamsString}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  getAdvisingCampuses(params) {
    if (params) {
      Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    }
    const queryParamsString = '?' + new HttpParams({ fromObject: params }).toString();
    return this._http.get(`/api/elastic/AdvisingCampusSearch` + queryParamsString).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  getAdvisingOrgs(params) {
    if (params) {
      Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    }
    const queryParamsString = '?' + new HttpParams({ fromObject: params }).toString();
    return this._http.get(`/api/elastic/AdvisingOrgSearch` + queryParamsString).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisingCampusesByOrg(org: string) {
    return this._http.get(`/api/elastic/AdvisingCampusesByOrg/${org}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisingCampusesByPlan(plan: string) {
    return this._http.get(`/api/elastic/AdvisingCampusesByPlan/${plan}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisingCampusesByMode(mode: string) {
    return this._http.get(`/api/elastic/AdvisingCampusesByMode/${mode}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisingOrgs(search?: string) {
    const searchString = !search ? '' : `?s=${search}`;
    return this._http.get(`/api/elastic/AdvisingOrgSearch${searchString}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisingOrgsByCampus(campus: string) {
    return this._http.get(`/api/elastic/AdvisingOrgsByCampus/${campus}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisingOrgsByPlan(plan: string) {
    return this._http.get(`/api/elastic/AdvisingOrgsByPlan/${plan}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  searchAdvisingOrgsByMode(mode: string) {
    return this._http.get(`/api/elastic/AdvisingOrgsByMode/${mode}`).pipe(
      map((results: any) => this.mapElasticData(results.hits))
    );
  }

  mapElasticData(dataSet: any[]): Array<any> {
    return dataSet.map(dat => {
      dat['_source'].elasticSort = dat['sort'];
      return dat['_source'];
    });
  }

}
