<app-dynamic-layout [sectionType]="'cta'"></app-dynamic-layout>
<app-dynamic-layout [sectionType]="'header'"></app-dynamic-layout>
<app-dynamic-layout [sectionType]="'breadcrumbs'"></app-dynamic-layout>

<div class="wrapper">
  <div class="outlet-page" [appShowLoadingAnim]="isLoading" loaderStyle="fa-3x my-5">
    <ng-template *ngIf="personId then person"></ng-template>
    <div class="container container-stretch pt-5">
      <ng-template *ngIf="!personId then default"></ng-template>
    </div>
  </div>
</div>

<ng-template #default>
  <div class="row">
    <div class="col mb-4">
      <h1>{{initOrgName}} Advising</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 order-lg-2">
      <div class="bg-nau-gray-darkest p-3 mb-3">
        <h3 class="mb-3 text-nau-trueblue">Filter Results</h3>
        <form [formGroup]="filterForm">
          <div class="input-group mb-3"  id="textFilter">
            <div class="input-group-prepend">
              <span class="input-group-text search-icon round">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <input
                id="name-filter"
                type="text"
                aria-label="Filter by name or office building"
                class="form-control"
                placeholder="Name or Office Building"
                autocomplete="off"
                formControlName="filter"/>
            <div class="input-group-append" id="button-addon4">
              <button class="btn btn-primary" type="button" (click)="clearTextFilter()" *ngIf="!!this.filterForm.get('filter').value">
                  <i class="fas fa-times-circle"></i>
              </button>
            </div>
          </div>
          <div class="mb-3 text-center">
            <div class="custom-control custom-radio custom-control-inline" *ngFor="let mode of modes">
              <input
                  type="radio"
                  [id]="'modeInput' + '-' + mode.n__campus_type"
                  name="mode" class="custom-control-input"
                  formControlName="mode"
                  [value]="mode.n__campus_type"
                  [attr.disabled]="mode.disabled">
              <label class="custom-control-label text-capitalize" [for]="'modeInput' + '-' + mode.n__campus_type">{{mode.descr}}</label>
            </div>
          </div>
          <ng-template #programTemplate let-r="result" let-t="term">
            <ngb-highlight [result]="r.diploma_descr" [term]="t"></ngb-highlight>
            <br>
            <span class="alt-text">{{r.n__degree_fdescr}}</span>
          </ng-template>
          <div class="input-group mb-3 dropdown-container" id="program-input">
            <div class="input-group-prepend">
              <span class="input-group-text search-icon">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <input
                #programDropdown="ngbTypeahead"
                class="form-control"
                name="program"
                [placeholder]="isProgramsLoading ? '' : focusDropdn === 'program' ? 'Type for more options' : 'Program (Major/Minor)'"
                formControlName="program"
                placement="bottom-right bottom-left"
                [ngbTypeahead]="programSearch"
                [resultTemplate]="programTemplate"
                [inputFormatter]="planFormatter"
                [editable]='false'
                (blur)="focusDropdn = ''"
                (click)="click$.next($any($event).target.value)"
                appNgbDropdownScroll />
            <div class="input-group-append">
              <button
                  class="btn"
                  type="button"
                  aria-label="clear advising program field"
                  (click)="selectProgram()"
                  [disabled]="!programs?.length"
                  *ngIf="!!filterForm.get('program').value">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <div class="dropdown-loader" *ngIf="!filterForm.get('program').value"><span [appShowLoadingAnim]="isProgramsLoading"></span></div>
          </div>
          <div class="input-group mb-3" id="location-input">
            <div class="input-group-prepend">
              <span class="input-group-text dd-icon">
                <i class="fas fa-sort"></i>
              </span>
            </div>
            <select class="custom-select form-control" formControlName="location" [ngClass]="{ 'text-muted': !filterForm.get('location').value }">
              <option value="null" disabled selected>
                <span *ngIf="!isLocationsLoading">Campus</span>
              </option>
              <option *ngFor="let location of locations" [value]="location.campus">{{location.descr}}</option>
            </select>
            <div class="dropdown-loader"><span [appShowLoadingAnim]="isLocationsLoading"></span></div>
            <div class="input-group-append">
              <button type="button" class="btn" aria-label="clear advising location field" (click)="selectLocation(null)" [disabled]="!filterForm.get('location').value">
                <i class="fas fa-times-circle" *ngIf="!!filterForm.get('location').value"></i>&nbsp;
              </button>
            </div>
          </div>
          <div class="input-group mb-3" id="org-input" *ngIf="((initOrgCount && initOrgCount > 1) || initOrgCount === undefined)">
            <div class="input-group-prepend">
              <span class="input-group-text dd-icon">
                <i class="fas fa-sort"></i>
              </span>
            </div>
            <select class="custom-select form-control" formControlName="org" [ngClass]="{ 'text-muted': !filterForm.get('org').value }">
              <option value="null" disabled selected>
                <span *ngIf="!isOrgsLoading">College/Department</span>
              </option>
              <option *ngFor="let org of orgs" [value]="org.acad_org">
                <span *ngIf="org.n__org_tree_lvl === 'DIVISION'">&nbsp;&nbsp;</span>
                <span *ngIf="org.n__org_tree_lvl === 'ACAD_UNIT'">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>
                  {{org.descrformal}}
                </span>
              </option>
            </select>
            <div class="dropdown-loader" ><span [appShowLoadingAnim]="isOrgsLoading"></span></div>
            <div class="input-group-append">
              <button type="button" class="btn" aria-label="clear advising college/department field" (click)="selectOrg(null)" [disabled]="!filterForm.get('org').value">
                <i class="fas fa-times-circle" *ngIf="!!filterForm.get('org').value"></i>&nbsp;
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-lg-8">
      <div [appShowLoadingAnim]="isAdvisorsLoading" loaderStyle="fa-2x">
        <div class="col-12 my-5 text-center" *ngIf="allPeopleFiltered || !people?.length">
          <h2>No <span *ngIf="allPeopleFiltered">filtered</span> results</h2>
        </div>
        <div class="mb-5" *ngIf="people?.length > 0 && !allPeopleFiltered">
          <ng-container *ngFor="let person of people">
            <app-info-card
                [contactInfo]="person"
                (cardClick)="handleSelectPerson($event)"
                *ngIf="!person.isFiltered"></app-info-card>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #person>
  <div class="contact-container">
    <div class="row py-4">
      <div class="col text-right">
        <button class="btn btn-sm btn-nau" (click)="showPreviousResults()">
          Back to advisors
        </button>
      </div>
    </div>
    <app-profile
      [contactId]="personId"
      [serviceName]="'advising'"></app-profile>
  </div>
</ng-template>