import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { AdvanceSearchComponent } from './../../shared/components/search/advance-search.component';
import { ScrollService } from '../../shared/services/scroll.service';
import { SearchStateService } from './../../shared/services/search-state.service';
import { SpaSettingsService } from './../../shared/services/spa-settings.service';
import { HeaderService } from '../../shared/services/header.service';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.sass']
})
export class FacultyComponent implements OnInit, OnDestroy {

  @ViewChild('search')
  search: AdvanceSearchComponent;
  stateChange: Subscription;
  showResults = false;
  isLoading = true;
  isSearchFocused: boolean;
  state: any;
  hasResults: boolean;

  constructor(
    private _scrollService: ScrollService,
    public _searchState: SearchStateService,
    private _headerService: HeaderService,
    private _settingsService: SpaSettingsService) {
      this.state = this._searchState.$faculty;
      this.stateChange = this._searchState.stateEvents['faculty'].subscribe(
        results => {
          // console.log('state change', results)
          if (!results) {
            this.showResults = false;
            /* console.log(this.search);
            if (!!this.search) {
              this.search.searchForm.reset();
            } */
          } else {
            this.hasResults = !!this.state.people && !!this.state.people.length;
            this.showResults = true;
          }
          this.isLoading = false;
          this._scrollService.scrollTo();
        });
    }

  ngOnInit() {
    // set focus on search if not first time page load
    if (this._settingsService.isSettingsLoaded()) {
      this.isSearchFocused = true;
    }
    //this._headerService.setHeader();
  }

  ngOnDestroy() {
    this.stateChange.unsubscribe();
  }

  handleSelectPerson(value: any, clearPreviousResults?: boolean) {
    const queryParams = this.state.searchFields;
    queryParams['person'] = value.selectedValue.uid;
    this._searchState.setNavigation(queryParams);
  }

  onSearchComplete(searchResults: any) {
    this.isLoading = false;
    this.showResults = searchResults !== null;
    this.hasResults = !!searchResults.length;
    this._searchState.setNavigation(this.state.searchFields);
  }

  previousView() {
    if (this.state.selectedValue) {
      if (this.state.people.length) {
        if (this.state.people.length === 1) {
          this.state.people = [];
          this._searchState.setNavigation();
        } else {
          this._searchState.setNavigation(this.state.searchFields, true);
        }
      } else {
        this._searchState.setNavigation();
      }
      this.state.selectedValue = null;
    } else if (this.state.people) {
      this.state.people = null;
      this._searchState.setNavigation();
    }
  }

  refresh() {
    this.state.searchFields.person = null;
    if (this.search) {
      this.state.searchFields = {};
      this.search.createForm();
    }
    this._searchState.setNavigation();
  }

}
