import { Observable, BehaviorSubject } from 'rxjs';
import { pluck, distinctUntilChanged } from 'rxjs/operators';

import { User } from './auth/shared/services/auth/auth.service';
import { MockData } from './directory/shared/services/mockdata.service';
import { Injectable } from '@angular/core';

export interface State {
  departmentTree: Array<any>;
  mockData: MockData[];
  resultSet: Array<any>;
  user: User;
}

const state: State = {
  departmentTree: undefined,
  mockData: undefined,
  resultSet: undefined,
  user: undefined
};

@Injectable()
export class Store {
  private subject = new BehaviorSubject<State>(state);
  private store = this.subject.asObservable().pipe(distinctUntilChanged());

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  set(name: string, newState: any) {
    this.subject.next({ ...this.value, [name]: newState });
  }

  resetState() {
    this.subject.next(state);
  }
}
