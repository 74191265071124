<div class="card mb-3" role="button" (click)="emitClick()" (keydown.enter)="emitClick()" (keydown.space)="emitClick()" tabindex="0">
  <div class="card-body" [ngClass]="{ 'p-2' : isCompact }">
    <div class="row mx-0 no-gutters" *ngIf="contactInfo else department">
      <ng-container>
        <div class="col">
          <div class="row">
            <div class="col-12">
              <div class="card-title">
                {{contactInfo.givenName}} <span *ngIf="contactInfo.middlename">{{contactInfo.middlename}} </span>{{contactInfo.sn}}
                <span class="pronouns" *ngIf="contactInfo.pronouns">(<a [href]="_spaSettings.pronounDocUrl"target="blank" (click)="$event.stopPropagation()">{{contactInfo.pronouns}}</a>)</span>
              </div>
              <div class="card-subtitle mb-3" *ngFor="let job of contactInfo.jobs">
                <div *ngIf="!isCompact">
                  <div [innerHTML]="job.title | breakOn:';'"></div>
                  <div>{{job.deptDescr}}</div>
                  <div class="mt-2" *ngIf="contactInfo.shortbio">
                    {{contactInfo.shortbio}}
                  </div>
                </div>
                <div [innerHTML]="job.title | breakOn:';'" *ngIf="isCompact"></div>
                <div *ngIf="isCompact && contactInfo.hasMultipleDepartments">{{job.deptDescr}}</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 card-subtitle" *ngIf="contactInfo.advisingspecialties">
            <div class="col-12">
              Advising Specialties:
              <span *ngFor="let specialty of contactInfo.advisingspecialties; let i = index">
              <span class="mr-2" *ngIf="i > 0 && i < contactInfo.advisingspecialties.length">,</span>
              {{specialty.specialtyDescr}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 col-lg-5">
              <div *ngIf="contactInfo.nauEduWorkMail" class="card-text" (click)="$event.stopPropagation()">
                <a
                    href="mailto:{{contactInfo.nauEduWorkMail}}"
                    target="_blank" rel="noopener"
                    (click)="$event.stopPropagation()">
                  {{contactInfo.nauEduWorkMail}}
                </a>
              </div>
              <div *ngIf="contactInfo.naueduworkphone" class="card-text" (click)="$event.stopPropagation()">
                <a
                    href="tel:{{contactInfo.naueduworkphone}}"
                    target="_blank" rel="noopener"
                    (click)="$event.stopPropagation()">
                  {{contactInfo.naueduworkphone | telephoneFormat}}
                </a>
              </div>
            </div>
            <div class="col-12 col-lg-7" *ngIf="contactInfo.building || contactInfo.bldg_cd || contactInfo.room">
              <div>
                <div class="location-text">
                  <span *ngIf="contactInfo.building">
                    Building:
                  </span><span class="d-inline-block"><span *ngIf="contactInfo.building">{{contactInfo.building}}</span><span *ngIf="contactInfo.bldg_cd">
                    ({{contactInfo.bldg_cd}})
                  </span>  <a
                        class="ml-1"
                        [href]="'https://www.google.com/maps/search/?api=1&query=' + contactInfo.latitude + ',' + contactInfo.longitude"
                        target="_blank" rel="noopener"
                        (click)="$event.stopPropagation()"
                        *ngIf="!!contactInfo.latitude && !!contactInfo.longitude">
                      <i class="fas fa-map-marker-alt"></i>
                    </a>
                  </span>
                </div>
                <div *ngIf="contactInfo.room">
                  Room: {{contactInfo.room}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div id="imageContainer">
            <img
                *ngIf="!isImageLoading && contactInfo.image"
                [src]="contactInfo.image?.src"
                id="directoryPhoto"
                [alt]="'directory photo for ' + contactInfo.givenName + ' ' + contactInfo.sn"
                [ngClass]="{ 'compact' : isCompact }">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #compactView>
  <div class="col pl-0 compact">
    <h3 class="card-title">
      {{contactInfo.givenName}} {{contactInfo.sn}}
      <small *ngIf="contactInfo.pronouns">(<a [href]="_spaSettings.pronounDocUrl" target="blank" (click)="$event.stopPropagation()">{{contactInfo.pronouns}}</a>)</small>
    </h3>
    <div *ngFor="let job of contactInfo.jobs" class="mb-2">
      <div [innerHTML]="job.title | breakOn:';'"></div>
      <div *ngIf="contactInfo.hasMultipleDepartments">{{job.deptDescr}}</div>
    </div>
  </div>
  <div class="col-auto px-0 text-right" *ngIf="isCompact">
    <div class="card-text mb-2" *ngIf="contactInfo.nauEduWorkMail">
      <a href="mailto:{{contactInfo.nauEduWorkMail}}" target="_blank" rel="noopener" (click)="$event.stopPropagation()">
        <i class="fas fa-envelope"></i>
      </a>
    </div>
    <div class="card-text mb-2" *ngIf="contactInfo.naueduworkphone">
      <a href="tel:{{contactInfo.naueduworkphone}}" target="_blank" rel="noopener" (click)="$event.stopPropagation()">
        <i class="fas fa-phone"></i>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #department>
  <div class="row">
    <div class="col-12">
      <div class="float-left">
        <h3 class="card-title">{{departmentInfo.indexDescr}}</h3>
      </div>
      <!-- <div class="float-right">
        <a
            href="{{departmentInfo.url}}"
            target="_blank" rel="noopener"
            class="text-right pull-right"
            *ngIf="departmentInfo.url"
            (click)="$event.stopPropagation()">
          {{departmentInfo.url}}<i class="fas fa-angle-right ml-1"></i> 
        </a>
      </div> -->
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="card-text mb-2" *ngIf="departmentInfo.email_addr">
        <a href="mailto:{{departmentInfo.email_addr}}" target="_blank" rel="noopener" (click)="$event.stopPropagation()">
          <i class="fas fa-envelope mr-2"></i>{{departmentInfo.email_addr}}
        </a>
      </div>
      <div class="card-text mb-2" *ngIf="departmentInfo.phone">
        <a href="tel:{{departmentInfo.phone}}" target="_blank" rel="noopener" (click)="$event.stopPropagation()">
          <i class="fas fa-phone mr-2"></i>{{departmentInfo.phone | telephoneFormat}}
        </a>
      </div>
      <div class="card-text mb-2" *ngIf="departmentInfo.url">
        <a
            href="{{departmentInfo.url}}"
            target="_blank" rel="noopener"
            *ngIf="departmentInfo.url"
            (click)="$event.stopPropagation()">
          {{departmentInfo.url}}
        </a>
      </div>
    </div>
    <div class="col-md-9 pt-2" *ngIf="departmentInfo.descrlong">
      {{departmentInfo.descrlong}}
    </div>
  </div>
</ng-template>
