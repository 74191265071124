import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { ElasticService } from './../../services/elastic.service';
import { SearchStateService } from './../../services/search-state.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'nau-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.sass']
})
export class AdvanceSearchComponent implements OnInit, AfterViewInit {

  @Input() searchType: string;
  @Input() searchValue: string;
  @Input() showSpinner = false;
  @Input() initSearch = {};
  @Input() isFocused: boolean;
  @Output() directorySet: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(NgbDropdown) departmentDropdown: NgbDropdown;
  @ViewChild('nameInput', { static: false }) public searchElem: ElementRef;
  searchForm: UntypedFormGroup;
  isSearchValid = false;
  directoryData: Array<any>;
  isLoading = false;
  departmentDropDownData = [];
  deptSearch: any;

  deptFormatter = (x: { n__dept_longdescr: string }) => x ? `${x.n__dept_longdescr}` : '';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _elastic: ElasticService,
    private _searchState: SearchStateService
  ) {
    this.deptSearch = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => {
          const trimmedTerm = term ? term.trim().replace(/[^a-zA-Z ]/g, '').trim() : '';
          if (trimmedTerm.length > 2) {
            return this.getDepartmentData(trimmedTerm);
          } else {
            return of([]);
          }
    }));
  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    this.searchForm
    .get('department')
    .valueChanges.subscribe(
      results => {
        if (typeof results !== 'string') {
          this._searchState[this.searchType].searchFields['department'] =  results.n__dept_longdescr;
          this._searchState[this.searchType].searchFields['deptid'] =  results.deptid;
          this.searchForm.get('deptid').setValue(results.deptid, { emitEvent: false });
        } else {
          this._searchState[this.searchType].searchFields['department'] =  results;
          this._searchState[this.searchType].searchFields['deptid'] = '';
          this.searchForm.get('deptid').setValue('', { emitEvent: false });
        }
      }
    );
    if (this.isFocused) {
      this.searchElem.nativeElement.focus(
        {
          preventScroll: true
        }
      );
    }
  }

  createForm() {
    const searchService = this.searchType;
    const validators = Validators.compose([Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9 \.\'\-\"]+$/)]);
    this.searchForm = this._formBuilder.group({
      department: [  '', Validators.compose([Validators.minLength(3), this.deptValidator])],
      keyword:    [ this._searchState[searchService].searchFields.keyword    || '', validators ],
      name:       [ this._searchState[searchService].searchFields.name       || '', validators ],
      title:      [ this._searchState[searchService].searchFields.title      || '', validators ],
      deptid:     [ this._searchState[searchService].searchFields.deptid     || '', validators ]
    });
    if (this._searchState[searchService].searchFields.department) {
      const tempDeptVal = { n__dept_longdescr:  this._searchState[searchService].searchFields.department };
      this.departmentDropDownData = [ tempDeptVal ];
      this.searchForm.get('department').setValue(tempDeptVal, { emitEvent: false });
    }
    this._searchState[searchService].searchFields = Object.assign({}, this._searchState[searchService].searchFields);
    Object.keys(this.searchForm.controls).forEach(key => {
      this.searchForm.get(key).valueChanges.subscribe(val => {
        let searchText = val;
        // trim extra quotes if there are an odd amount
        if (searchText && searchText.typeof === 'string' && (searchText.match(/"/g) || []).length % 2 === 1) {
          const lastQuote = searchText.lastIndexOf('"');
          searchText = searchText.slice(0, lastQuote) + searchText.slice(lastQuote + 1);
        }
        this._searchState[searchService].searchFields[key] = searchText;
        this.isSearchValid = this.checkformValidity();
      });
    });
    this.isSearchValid = this.checkformValidity();
  }

  checkformValidity() {
    let foundVal = false;
    let hasError = false;
    Object.keys(this.searchForm.value).forEach(key => {
      if (!foundVal && this.searchForm.get(key).value) {
        foundVal = true;
      }
      if (!hasError && this.searchForm.get(key).errors) {
        hasError = true;
      }
    });
    return foundVal && !hasError;
  }

  deptValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!!control.value && typeof control.value === 'string' && !control.value.match(/^[a-zA-Z0-9 \-]+$/)) {
      return { 'dept': true };
    }
    return null;
  }

  getDirectoryData(searchFields?: any): void {
    const searchOn = searchFields ? searchFields : _.clone(this._searchState[this.searchType].searchFields);
    if (searchOn.deptid) {
      searchOn.department = '';
    } else if (searchOn.department && typeof searchOn.department !== 'string') {
      searchOn.department = searchOn.department.n__dept_longdescr;
    }
    let search;
    switch (this.searchType) {
    case 'staff':
      search = this._elastic.searchStaff(searchOn, 5000);
      break;
    case 'faculty':
      search = this._elastic.searchFaculty(searchOn, 5000);
      break;
    }
    search.subscribe(
      data => {
        this._searchState[this.searchType].people = this._elastic.mapElasticData(data.hits);
        this.directorySet.emit(this._searchState[this.searchType].people);
        this.isLoading = false;
      },
      error => {
        return of([]);
      }
    );
  }

  getDepartmentData(term: string): Observable<any> {
    return this._elastic.searchDepartments(term).pipe(
      map(results => {
        return this._elastic.mapElasticData(results.hits).slice(0, 8);
      },
      error => {
        return of([]);
      })
    );
  }

  onSubmit(searchFields?: any) {
    this.isLoading = true;
    this.getDirectoryData(searchFields);
  }

  clearInput(inputId: string) {
    this.searchForm.get(inputId).setValue('');
    this._searchState[this.searchType].searchFields[inputId] = '';
    if (inputId === 'department') {
      this.searchForm.get('deptid').setValue('', { emitEvent: false });
      this._searchState[this.searchType].searchFields['deptid'] = '';
    }
  }

}
