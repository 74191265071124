<div id="spinner-container" class="d-table" [ngClass]="{'h-100 w-100 position-absolute' : absolute}" tabindex="0" aria-label="Loading">
  <div class="d-table-cell align-middle text-center position-sticky" aria-hidden="true">
    <i class="fas fa-circle-notch fa-spin text-nau-trueblue" [ngClass]="style"></i>
  </div>
</div>

<!-- <div id="mainLoader" class="d-table h-100 w-100 position-absolute">
  <div class="d-table-cell align-middle text-center">
    <i class="fas fa-circle-notch fa-spin fa-4x text-nau-trueblue"></i>
  </div>
</div> -->