import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { of, Subject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpaSettingsService   {

  public imageServerUrl: string;
  public pronounDocUrl: string;
  public wordpressUrl: string;
  public settingsRequest = new Subject<any>();
  private settingsLoaded = false;

  constructor(private _http: HttpClient) {
    this._http.get(`/api/spa/spaUrls`).subscribe(
      (response: any) => {
        this.imageServerUrl = response.imageServerUrl;
        this.pronounDocUrl = response.pronounDocUrl;
        this.wordpressUrl = response.wordpressUrl;
        this.settingsLoaded = true;
        this.settingsRequest.next(response);
      },
      error => this.settingsRequest.next({ wordpressUrl: null })
    );
  }

  isSettingsLoaded() {
    return this.settingsLoaded;
  }

  canActivate() {
    if (this.settingsLoaded) {
      return true;
    }
    return this.settingsRequest.pipe(map(response => true));
  }

}
