import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ContactService } from '../../shared/services/contact.service';
import { of, Subject, Subscription, Observable, pipe, forkJoin } from 'rxjs';
import { map, tap, debounceTime, distinctUntilChanged, switchMap, filter, merge } from 'rxjs/operators';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ElasticService } from '../../shared/services/elastic.service';
import { SearchStateService } from '../../shared/services/search-state.service';
import { ScrollService } from './../../shared/services/scroll.service';
import * as _ from 'lodash';
import { HeaderService } from '../../shared/services/header.service';

@Component({
  selector: 'app-advising',
  templateUrl: './advising.component.html',
  styleUrls: ['./advising.component.sass']
})
export class AdvisingComponent implements OnInit {

  @ViewChild('programDropdown', { static: true }) programDropdown: NgbTypeahead;
  @ViewChild('locationDropdown', { static: true }) locationDropdown: NgbTypeahead;
  @ViewChild('orgDropdown', { static: true }) orgDropdown: NgbTypeahead;

  programFocus$ = new Subject<string>();
  locationFocus$ = new Subject<string>();
  orgFocus$ = new Subject<string>();
  click$ = new Subject<string>();
  stateChange: Subscription;
  programSearch: any;
  isLoading: boolean;
  people: any[];
  personId: string;
  initOrgId: string;
  initOrgName: string;
  initOrgCount: number;
  orgId: string;
  planId: string;
  planCareer: string;
  campusId: string;
  mode: any;
  gateway: string;
  programs: any[];
  locations: any[];
  orgs: any[];
  isAdvisorsLoading: boolean;
  isProgramsLoading: boolean;
  isLocationsLoading: boolean;
  isModesLoading: boolean;
  isOrgsLoading: boolean;
  allPeopleFiltered: boolean;
  filterCount: number;
  filterForm: UntypedFormGroup;
  source: string;
  dropDownRequests: Subscription;
  modes = [
    { n__campus_type: 'A', descr: 'all', disabled: null },
    { n__campus_type: 'O', descr: 'online', disabled: null },
    { n__campus_type: 'IP', descr: 'in person', disabled: null }];

  planFormatter = (x: { diploma_descr: string, n__degree_fdescr: string }) => x ? `${x.diploma_descr} (${x.n__degree_fdescr})` : '';

  constructor(
      public _searchState: SearchStateService,
      private _elasticService: ElasticService,
      private _scrollService: ScrollService,
      private _headerService: HeaderService,
      private _forms: UntypedFormBuilder) {
    this.stateChange = this._searchState.stateEvents.advising.subscribe(
      state => {
        this.personId = !!state.person ? state.person : null;
        this.isLoading = true;
        this.initOrgId = state.org ? state.org.toUpperCase() : undefined;
        this.orgId = this.initOrgId;
        this.gateway = state.gateway ? state.gateway.toUpperCase() : '';
        this.source = state.source;
        if (!this.personId) {
          const advisingRequests = [
            !!this.orgs ? of(null) : this.populateOrgs(!!this.initOrgId ? { org: this.initOrgId } : null),
            !!this.programs ? of(null) : this.populatePlans(!!this.initOrgId ? { org: this.initOrgId } : null),
            !!this.locations ? of(null) : this.populateLocations(!!this.initOrgId ? { org: this.initOrgId } : null)
          ];
          if (!this.dropDownRequests) {
            advisingRequests.push(this.populateAdvisors(!!this.initOrgId ? { org: this.initOrgId } : null));
          }
          const ddReq = forkJoin(advisingRequests).subscribe(
            results => {
              this.isLoading = false;
              this.isAdvisorsLoading = false;
            }
          );
        } else {
          this.isLoading = false;
          this.isAdvisorsLoading = false;
        }
    });
    this.mode = this.modes[0];
    this.programSearch = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.programDropdown && !this.programDropdown.isPopupOpen()));
      const inputFocus$ = this.programFocus$;
      return debouncedText$.pipe(
        merge(inputFocus$, clicksWithClosedPopup$),
        map((term: string) => {
          const trimmedTerm = term ? term.trim().replace(/[^a-zA-Z ]/g, '').trim() : '';
          if (trimmedTerm.length > 2) {
            return this.populateDisplayDropdown(this.programs, 'diploma_descr',  trimmedTerm);
          } else {
            return this.programs.slice(0, 20);
          }
        }));
    };
  }

  ngOnInit() {
    this.initFilterForm();
    //this._headerService.setHeader();
  }

  initFilterForm() {
    this.filterForm = this._forms.group({
      filter:   [''],
      mode:     ['A'],
      location: [],
      org:      [],
      program:  ['']
    });
    Object.keys(this.filterForm.controls).forEach(key => {
      this.filterForm.get(key).valueChanges.subscribe(val => {
        this.handleFilterControlChange(key, val);
      });
    });
  }

  selectProgram(program: any) {
    let doUpdate = true;
    if (!program) {
      if (!this.planId) {
        doUpdate = false;
      }
      this.planId = this.planCareer = null;
    } else {
      this.planId = program.acad_plan;
      this.planCareer = program.acad_career;
    }
    this.filterForm.get('program').setValue(program, { emitEvent: false });
    if (doUpdate) {
      this.updateFilterControls('program', program ? program.acad_plan : null);
    }
  }

  selectLocation(location: any) {
    if ((!location && !this.campusId) || (location === this.campusId)) { return; }
    this.campusId = location;
    this.filterForm.get('location').setValue(location, { emitEvent: false });
    this.updateFilterControls('location', location);
  }

  selectOrg(org: any) {
    if ((!org && !this.orgId) || (org === this.orgId)) { return; }
    this.orgId = org;
    this.filterForm.get('org').setValue(this.orgId, { emitEvent: false });
    this.updateFilterControls('org', org ? org : null);
  }

  populateDisplayDropdown(dropdownArray: any[], filterField: string, searchTerm: string): any[] {
    if (searchTerm.length === 1) {
      return dropdownArray.filter(element => {
        return element[filterField].toLowerCase().startsWith(searchTerm.toLocaleLowerCase());
      });
    } else if (searchTerm.length > 1) {
      return dropdownArray.filter(element => {
        return element[filterField].toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) !== -1;
      });
    }
  }

  handleFilterControlChange(controlName: string, controlValue: string) {
    if (!controlValue) { return; }
    switch (controlName) {
    case 'mode':
      this.mode = this.modes.find(modeVal => modeVal.n__campus_type === controlValue);
      this.updateFilterControls(controlName, controlValue);
      break;
    case 'filter':
      this.onFilter(controlValue);
      return;
    case 'program':
      this.selectProgram(controlValue);
      break;
    case 'location':
      this.selectLocation(controlValue);
      break;
    case 'org':
      this.selectOrg(controlValue);
      break;
    }
  }

  onFilter(filterText: string) {
    this.filterCount = 0;
    this.people.forEach(person => {
      person.isFiltered = this.isFiltered(person, filterText);
      if (!person.isFiltered) {
        this.allPeopleFiltered = false;
      } else {
        this.filterCount++;
      }
      return person;
    });
    this.allPeopleFiltered = this.filterCount === this.people.length;
  }

  isFiltered(contact: any, filterText: string) {
    let isFiltered = false;
    const term = filterText.toLowerCase();
    let nameMatcher = '';
    nameMatcher += contact.givenName ? contact.givenName.toLowerCase() + ' ' : '';
    nameMatcher += contact.sn ? contact.sn.toLowerCase() : '';
    isFiltered = !nameMatcher.includes(term) && (contact.building ? !contact.building.toLowerCase().includes(term) : true);
    return isFiltered;
  }

  clearTextFilter() {
    this.filterForm.get('filter').setValue('');
    this.onFilter('');
  }

  handleSelectPerson(value: any) {
    const navigationValues: any = { person: value.selectedValue.uid };
    if (this.initOrgId) {
      navigationValues.org = this.initOrgId;
    }
    if (this.source) {
      navigationValues.src = this.source;
    }
    navigationValues.gateway = this.gateway;
    this._scrollService.scrollTo();
    setTimeout(() => {
      this.isLoading = true;
      this._searchState.setNavigation(navigationValues);
    }, 50);
  }

  showPreviousResults() {
    let navigationValues: any = !!this.initOrgId ? { org: this.initOrgId } : {};
    if (this.initOrgId) {
      navigationValues.org = this.initOrgId;
    }
    if (this.source) {
      navigationValues.src = this.source;
    }
    navigationValues = navigationValues || {};
    navigationValues.gateway = this.gateway;
    this._searchState.setNavigation(navigationValues);
  }

  updateFilterControls(filterTrigger: string, filterValue: any) {
    // current filter state
    const includeMode = !!this.mode;
    const includePlan = !!this.planId;
    const includeOrg = !!this.orgId && this.orgId !== this.initOrgId;
    const includeCampus = !!this.campusId;
    const org = this.orgId ? this.orgId : this.initOrgId;
    let mode;
    switch (this.mode.n__campus_type) {
    case 'A':
      mode = null;
      break;
    case 'IP':
      mode = 'inperson';
      break;
    case 'O':
      mode = 'online';
      break;
    }

    // create default advising data query parameters
    const advisorQuery: any = {
      org:    !this.planId ? org : null,
      plan:   this.planId,
      career: this.planCareer,
      mode:   !this.campusId ? mode : null,
      campus: this.campusId };

    const orgQuery: any = {
      org:    !this.planId ? this.initOrgId : null,
      plan:   this.planId,
      campus: !this.planId ? this.campusId : null,
      mode:   !this.campusId && !this.planId ? mode : null };

    const locationQuery: any = {
      org: !this.planId ? org : null,
      mode: mode,
      plan: this.planId };

    const planQuery: any = {
      org:    org,
      campus: this.campusId,
      mode:   !this.campusId ? mode : null };

    const modeQuery: any = {
      org:    !this.planId ? org : null,
      plan:   this.planId,
      campus: this.campusId };

    const orgRequest      = filterTrigger === 'org'      ? of(null) : this.populateOrgs(orgQuery);
    const planRequest     = filterTrigger === 'program'  ? of(null) : this.populatePlans(planQuery);
    const locationRequest = filterTrigger === 'location' ? of(null) : this.populateLocations(locationQuery);
    const advisorRequest  = this.populateAdvisors(advisorQuery);
    const modeRequest     = this.setModes(modeQuery);

    if (this.dropDownRequests) {
      this.dropDownRequests.unsubscribe();
    }
    this.dropDownRequests = forkJoin([advisorRequest, orgRequest, planRequest, locationRequest, modeRequest]).subscribe(
      results => {
        this.isLoading = false;
        this.isAdvisorsLoading = false;
        this.isModesLoading = false;
      }
    );
  }

  populateAdvisors(searchVals: any) {
    this.isAdvisorsLoading = true;
    searchVals = searchVals || {};
    searchVals.gateway = this.gateway;
    return this._elasticService.getAdvisors(searchVals).pipe(
      tap((advisors: any[]) => {
        this.people = advisors;
      })
    );
  }

  populatePlans(searchVals?: any): Observable <any> {
    this.isProgramsLoading = true;
    return this._elasticService.getAdvisingPlans(searchVals).pipe(
      tap((plans: any[]) => {
        this.isProgramsLoading = false;
        this.programs = plans;
        if (!plans.length) {
          this.filterForm.get('program').setValue(null, { emitEvent: false });
          this.filterForm.get('program').disable();
        } else {
          this.filterForm.get('program').enable();
        }
      })
    );
  }

  populateLocations(searchVals?: any) {
    this.isLocationsLoading = true;
    if (this.filterForm) {
      this.filterForm.get('location').patchValue(null, { emitEvent: false});
    }
    return this._elasticService.getAdvisingCampuses(searchVals).pipe(
      tap((locations: any[]) => {
        this.locations = locations;
        setTimeout(() => {
          this.isLocationsLoading = false;
          this.filterForm.get('location').patchValue(this.campusId ? this.campusId : null, { emitEvent: false});
        });
      })
    );
  }

  populateOrgs(searchVals?: any) {
    this.isOrgsLoading = true;
    if (this.filterForm) {
      this.filterForm.get('org').patchValue(null, { emitEvent: false});
    }
    return this._elasticService.getAdvisingOrgs(searchVals).pipe(
      tap((orgs: any[]) => {
        setTimeout(() => this.isOrgsLoading = false);
        this.orgs = orgs;
        let initOrg;
        if (this.initOrgId) {
          if (!this.initOrgName && !!(initOrg = this.orgs.find(org => org.acad_org.toLowerCase()))) {
            this.initOrgName = initOrg.descrformal;
          }
          if (!this.initOrgCount) {
            this.initOrgCount = this.orgs.length;
          }
          if (this.initOrgId === this.orgId) { return; }
        }
        setTimeout(() => this.filterForm.get('org').patchValue(this.orgId ? this.orgId : null, { emitEvent: false}));
      })
    );
  }

  setModes(searchVals?: any) {
    this.isModesLoading = true;
    return this._elasticService.getAdvisingModes(searchVals).pipe(
      tap((modeResponses: any[]) => {
        this.modes.forEach(mode => {
          if (mode.n__campus_type === 'A') {
            mode.disabled = modeResponses.length === 2 ? null : true;
          } else {
            const foundMode = modeResponses.find(modeResponse => modeResponse.n__campus_type === mode.n__campus_type);
            mode.disabled = !!foundMode ? null : true;
            if (!mode.disabled && modeResponses.length === 1) {
              this.filterForm.get('mode').patchValue(mode.n__campus_type, { emitEvent: false });
            }
          }
        });
      })
    );
  }

  setDepartmentPeople(advisors: any[]) {
    if (!advisors.length) {
      return [];
    }
    this.people = advisors;
  }

}
