<form
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
    [formGroup]="searchForm">
  <div class="row" *ngIf="showSpinner && isLoading">
    <div class="col my-5 text-center">
      <i class="fas fa-circle-notch fa-spin fa-4x text-nau-trueblue"></i>
    </div>
  </div>
  <ng-container *ngIf="!showSpinner || !isLoading">
    <div class="form-row mb-4">
      <div class="col">
        <label for="nameInput"><h5>Name</h5></label>
        <div class="input-group">
          <span class="input-container">
            <input
              #nameInput
              type="text"
              class="form-control outline"
              [ngClass]="{'is-invalid': !!searchForm.get('name').value && searchForm.get('name').invalid}"
              id="nameInput"
              formControlName="name">
            <span *ngIf="!!searchForm.get('name').value">
              <button class="btn btn-primary" type="button" (click)="clearInput('name'); nameInput.focus()" aria-label="clear name">
                <i class="fas fa-times-circle" aria-hidden="true"></i>
              </button>
            </span>
            <div class="invalid-feedback" *ngIf="searchForm.get('name').errors">
              <span *ngIf="searchForm.get('name').errors.minlength">
                Must be at least 3 characters
              </span>
              <span *ngIf="searchForm.get('name').errors.minlength && searchForm.get('name').errors.pattern">
                ,
              </span>
              <span *ngIf="searchForm.get('name').errors.pattern">
                Must be alphanumeric
              </span>
            </div>
          </span>
        </div>

    </div>
    </div>
    <div class="form-row my-4">
      <div class="col">
        <label for="titleInput"><h5>Job Title</h5></label>
        <div class="input-group">
          <span class="input-container">
            <input
                #titleInput
                type="text"
                class="form-control outline"
                [ngClass]="{'is-invalid': !!searchForm.get('title').value && searchForm.get('title').invalid}"
                id="titleInput"
                formControlName="title">
            <span *ngIf="!!searchForm.get('title').value">
              <button class="btn btn-primary" type="button" (click)="clearInput('title'); titleInput.focus()" aria-label="clear title">
                <i class="fas fa-times-circle" aria-hidden="true"></i>
              </button>
            </span>
            <div class="invalid-feedback"  *ngIf="searchForm.get('title').errors">
              <span *ngIf="searchForm.get('title').errors.minlength">
                Must be at least 3 characters
              </span>
              <span *ngIf="searchForm.get('title').errors.minlength && searchForm.get('title').errors.pattern">
                ,
              </span>
              <span *ngIf="searchForm.get('title').errors.pattern">
                Must be alphanumeric
              </span>
            </div>
          </span>
        </div>
      </div>
      <div class="col">
        <div ngbDropdown #departmentDropdown="ngbDropdown" placement="bottom-right">
          <label for="departmentInput"><h5>Department</h5></label>
          <div class="input-group">
            <span class="input-container">
              <input
                  #departmentInput
                  id="departmentInput"
                  type="text"
                  class="form-control outline"
                  [ngClass]="{'is-invalid': !!searchForm.get('department').value && searchForm.get('department').invalid}"
                  formControlName="department"
                  placement="bottom-right bottom-left"
                  [ngbTypeahead]="deptSearch"
                  [resultTemplate]="deptListing"
                  [inputFormatter]="deptFormatter"
                  appNgbDropdownScroll/>
              <span *ngIf="!!searchForm.get('department').value">
                <button class="btn btn-primary" type="button" (click)="clearInput('department'); departmentInput.focus()" aria-label="clear department">
                  <i class="fas fa-times-circle" aria-hidden="true"></i>
                </button>
              </span>
              <div class="invalid-feedback" *ngIf="searchForm.get('department').errors">
                <span *ngIf="searchForm.get('department').errors.minlength">
                  Must be at least 3 characters
                </span>
                <span *ngIf="searchForm.get('department').errors.minlength && searchForm.get('department').errors.dept">
                  ,
                </span>
                <span *ngIf="searchForm.get('department').errors.dept">
                  Must be alphanumeric
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row my-4" *ngIf="searchType === 'faculty'">
      <div class="col">
        <label for="keywordInput"><h5>Keyword</h5></label>
        <div class="input-group">
          <span class="input-container">
            <input
                #keywordInput
                type="text"
                class="form-control outline"
                [ngClass]="{'is-invalid': !!searchForm.get('keyword').value && searchForm.get('keyword').invalid}"
                id="keywordInput"
                formControlName="keyword">
            <span *ngIf="!!searchForm.get('keyword').value">
              <button class="btn btn-primary" type="button" (click)="clearInput('keyword'); keword.focus()" aria-label="clear keword">
                <i class="fas fa-times-circle" aria-hidden="true"></i>
              </button>
            </span>
            <div class="invalid-feedback position-absolute" *ngIf="searchForm.get('keyword').errors">
              <span *ngIf="searchForm.get('keyword').errors.minlength">
                Must be at least 3 characters
              </span>
              <span *ngIf="searchForm.get('keyword').errors.minlength && searchForm.get('keyword').errors.pattern">
                ,
              </span>
              <span *ngIf="searchForm.get('keyword').errors.pattern">
                Must be alphanumeric
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="form-row mb-4 mt-5">
      <div class="col text-center">
        <button class="btn btn-nau text-capitalize" [disabled]="!isSearchValid">
          Search {{searchType}}
        </button>
      </div>
    </div>
  </ng-container>
</form>

<ng-template #deptListing let-r="result" let-t="term">
  <ngb-highlight [result]="r.n__dept_longdescr" [term]="t"></ngb-highlight>
</ng-template>
